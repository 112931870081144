import axios from "axios";
import React, { createContext, useReducer } from "react";
import GlobalReducer from "./GlobalReducer";

const initialState = {
  sidebar: false,
  statistics: null,
  user: null,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState);
  const closeNav = () => {
    dispatch({
      type: "CLOSE_NAV",
      payload: false,
    });
  };

  const openNav = () => {
    dispatch({
      type: "OPEN_NAV",
      payload: true,
    });
  };

  const storeStatistics = async () => {
    const _tk =
      typeof window !== undefined
        ? JSON.parse(sessionStorage.getItem("v_tk"))
        : null;

    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASEURL}/vendor/overview`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );
      dispatch({
        type: "STORE_STATISTICS",
        payload: request.data.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "STORE_STATISTICS",
        payload: null,
      });
    }
  };

  const storeUserDetails = async (token) => {
    const _tk =
      typeof window !== undefined
        ? JSON.parse(sessionStorage.getItem("v_tk"))
        : null;

    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASEURL}/vendor/auth`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token ? token : _tk}`,
          },
        }
      );
      dispatch({
        type: "STORE_USER_DETAILS",
        payload: request.data.data,
      });
      sessionStorage.setItem("user", JSON.stringify(request.data.data));
    } catch (error) {
      console.log(error);
      dispatch({
        type: "STORE_USER_DETAILS",
        payload: null,
      });
    }
  };

  // Store details on load

  return (
    <GlobalContext.Provider
      value={{
        sidebar: state.sidebar,
        closeNav,
        openNav,
        storeStatistics,
        statistics: state.statistics,
        storeUserDetails,
        user: state.user,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
