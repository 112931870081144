import { useState, useContext } from "react";
import { MainContext } from "../App";
// import Loading from '../components/loading/loading';

export const LogoutUserDetails = () => {
  const CTX = useContext(MainContext);
  const [loading, setLoading] = useState(false);

  const logout = async () => {
    try {
      await CTX.setUser({});
      await CTX.setToken("");
      await sessionStorage.clear();
    } catch (error) {
      setLoading(false);
      // setMsg('Invalid login details');
      console.log(error);
    }
  };

  //   useEffect(() => {
  // logout()
  //   },[])

  return { loading, logout };
};
