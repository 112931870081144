import React from "react";
import { Outlet } from "react-router-dom";
import Nav from "../nav/nav";
import Sidebar from "../sidebar/sidebar";
import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalProvider";
import { Suspense } from "react";
import Loader from "../../loader";
const Main = () => {
  const { sidebar, closeNav } = useContext(GlobalContext);
  return (
    <div className="">
      <Nav />
      <div className="mt-[80px]">
        <Sidebar />
      </div>
      <div
        onClick={() => closeNav()}
        className={`fixed transbox z-50 h-full top-0 w-full left-0 ${
          sidebar ? "block" : "hidden"
        }`}
      />
      <div className="lg:pl-[290px]">
        <Suspense
          fallback={
            <h1 className="text-center mt-5 text-xl">
              <Loader />
            </h1>
          }
        >
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};

export default Main;
