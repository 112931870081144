import axios from "../../config/axios";
import { useMutation } from "@tanstack/react-query";

const useVendorValidateMutation = (userDetails) => {
  const vendorValidate = async () => {
    try {
      const request = await axios.post(`vendor/validate`, {
        email: userDetails?.email,
        store_name: userDetails?.store_name,
      });

      const { data } = request;
      return data;
    } catch (error) {
      // toast(error.response.data.detail ?? "Something went wrong");
    }
  };

  return useMutation({
    mutationFn: vendorValidate,
  });
};

export default useVendorValidateMutation;
