import React, { useState } from "react";

import Classes from "./reset.module.css";
import SemiLogo from "../../asset/logo_semi.png";
import RightLogo from "../../asset/star.png";
import Logo from "../../asset/logo.png";
import Input from "../../component/input/input";
import Button from "../../component/button/button";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ResetJS = ({ inputs, setInputs, onSubmitHandler, msg, loading, CTX }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [anoShowPassword, setAnoShowPassword] = useState(false);
  const [showPassMSG, setShowPassMSG] = useState(false);
  const [showComPassMSG, setShowComPassMSG] = useState(false);
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

  // const confirmPassword = e => {

  // }

  return (
    <div className={Classes.LoginFormCover}>
      <Helmet>
        <title>Reset Password - Passward Vendor </title>
        <meta name="og:title" content="Reset Password - Passward Vendor" />
        <meta
          name="og:description"
          content="Reset Password - Passward Vendor"
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={Classes.relative}>
        <nav className={Classes.nav}>
          <Link to="/" onClick={() => CTX.closeLogin()}>
            <img src={Logo} className={Classes.logo} alt="Passward Logo" />
          </Link>
        </nav>
        <h1 className={Classes.header}>Reset Password</h1>
        <form
          autoComplete="off"
          className={Classes.formCover}
          onSubmit={onSubmitHandler}
        >
          {msg.length > 0 && (
            <div
              className={Classes.msgAlert}
              style={{
                textAlign: "center",
                marginBottom: "40px",
                display: "none",
              }}
            >
              <p style={{ margin: "0px 0px" }} className={Classes.msgItself}>
                {msg}
              </p>
            </div>
          )}

          <p className={Classes.EnterSection}>
            Enter a new password to reset your password
          </p>

          <div className={Classes.main_width} style={{ marginTop: "8px" }}>
            <Input
              type="input"
              required={true}
              value={inputs?.new_password}
              // onChange={(e) => setInputs({...inputs, new_password: e.target.value})}
              onChange={(e) => {
                setInputs({ ...inputs, new_password: e.target.value });
                if (re.test(e.target.value)) {
                  return setShowPassMSG(false);
                } else {
                  return setShowPassMSG(true);
                }
              }}
              labelStyle={{ color: "#000033" }}
              placeholder="* * * * * * *"
              showPassword={() => setShowPassword(!showPassword)}
              inputType={showPassword ? "text" : "password"}
              label="New Password"
              style={{ backgroundColor: "#fff", height: "50px" }}
            />
            {showPassMSG && (
              <>
                {inputs?.new_password?.length > 1 && (
                  <p className={Classes.must_Contain}>
                    Password must contain an Uppercase, Lowercase and Symbol
                  </p>
                )}
              </>
            )}
          </div>

          <div className={Classes.main_width} style={{ marginTop: "8px" }}>
            <Input
              type="input"
              required={true}
              // onBlur={confirmPassword}
              value={inputs?.confirm_password}
              onChange={(e) => {
                setInputs({ ...inputs, confirm_password: e.target.value });
                if (e.target.value !== inputs?.new_password) {
                  setShowComPassMSG(true);
                } else {
                  setShowComPassMSG(false);
                }
              }}
              labelStyle={{ color: "#000033" }}
              placeholder="* * * * * * *"
              showPassword={() => setAnoShowPassword(!anoShowPassword)}
              inputType={anoShowPassword ? "text" : "password"}
              label="Confirm Password"
              style={{ backgroundColor: "#fff", height: "50px" }}
            />
            {showComPassMSG && (
              <p className={Classes.must_Contain}>Passwords do not match</p>
            )}
          </div>

          {/* {!register && ( */}
          <div className={Classes.main_width} style={{ marginTop: "18px" }}>
            <Button
              text=" Reset Password"
              style={{ width: "100%", height: "40px", marginBottom: "50px" }}
              loading={loading}
            />
          </div>

          <Link to="/login">
            <div className={Classes.Back_to_Login}>Back to Login</div>
          </Link>

          {/* )} */}
        </form>

        <img src={SemiLogo} className={Classes.topRightLogo} />
        <img src={RightLogo} className={Classes.rightLogo} />
        <img src={RightLogo} className={Classes.leftLogo} />
        <img src={RightLogo} className={Classes.topLeftLogo} />
        <img src={SemiLogo} className={Classes.semiLogo} />
      </div>
    </div>
  );
};

export default ResetJS;
