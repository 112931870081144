import React, { useState, useEffect, useRef } from "react";
import EachOrder from "./eachOrder";
import { useParams } from "react-router-dom";

const EventEachOrder = () => {
  const [itemsPerPage, setItemsPerPage] = useState("10");
  const orderParams = useParams();
  const data = {
    order_status: false,
    name: "Sopsop Deliveries",
    phone: "08106046040",
  };
  const [orders, setOrders] = useState({ order: [] });
  const [loading, setLoading] = useState(false);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const refReactPaginate = useRef();
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;
  const getAllOrder = async () => {
    try {
      setLoading(true);
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/orders/view/${orderParams.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );
      const json = await fetched.json();
      setLoading(false);

      if (!json.data.customer) {
        return;
      } else {
        return setOrders(json.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrder();
  }, [orderParams.id]);

  useEffect(() => {
    if (orders.order.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = orders.order.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(orders.order.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, orders]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % orders.length;
    setItemOffset(newOffset);
  };

  const onChangeHandler = (e) => {
    setItemsPerPage(e.target.value.split(" ")[1]);
  };

  return (
    <EachOrder
      data={data}
      currentItems={currentItems}
      pageCount={pageCount}
      pageNum={pageNum}
      loading={loading}
      handlePageClick={handlePageClick}
      refReactPaginate={refReactPaginate}
      orders={orders}
      onChangeHandler={onChangeHandler}
    />
  );
};

export default EventEachOrder;
