import axios from "../../config/axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const useGetUserIpAdressQuery = () => {
  const getUserIpAdress = async () => {
    try {
      const request = await axios.get("https://api.ipify.org?format=json");
      const { data } = request;
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ["user-ip"],
    queryFn: getUserIpAdress,
    onSuccess: () => {
      queryClient.invalidateQueries(["subscription-info"]);
    },
  });

  return query;
};

export default useGetUserIpAdressQuery;
