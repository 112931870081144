// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card_cardCover__QzFAw{\n    background-color: #E9EAF9;\n    padding: 1.5rem;\n    box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);\n    margin-bottom: 24px;\n    border-radius: 0.25rem;\n    box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://./src/component/card/card.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IAEf,6CAA6C;IAC7C,mBAAmB;IACnB,sBAAsB;IACtB,sBAAsB;AAC1B","sourcesContent":[".cardCover{\n    background-color: #E9EAF9;\n    padding: 1.5rem;\n    -webkit-box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);\n    box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);\n    margin-bottom: 24px;\n    border-radius: 0.25rem;\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardCover": "card_cardCover__QzFAw"
};
export default ___CSS_LOADER_EXPORT___;
