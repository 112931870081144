import React from "react";
import { Route, Routes } from "react-router-dom";
import EventForgot from "../pages/forgotPassword/forgotLogin";
import EventReset from "../pages/resetPassword/eventReset";
import Plans from "../pages/plans/plans";
import VerificationPage from "../pages/verificationPage/verificationPage";
import ProtectedRoutes from "./ProtectedRoutes";
import Main from "../component/layout/main";
import Customer from "../pages/customer/eventCustomer";
import EventProducts from "../pages/products/eventProducts";
import Orders from "../pages/orders/eventOrders";
import EventEachOrder from "../pages/eachOrder/eventEachOrder";
import EventWallet from "../pages/wallet/eventWallet";
import EventSubscription from "../pages/subscription/eventSubscription";
import WithdrawalSettings from "../pages/settings/settings";
import SingleProduct from "../pages/singleProduct/singleProduct";
import Home from "../pages/dashboard/dashboard";
import SubscribedRoutes from "./SubscribedRoutes";
import SignIn from "../pages/login/signin";
import Register from "../pages/login/register";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<ProtectedRoutes />}>
          <Route element={<Main />}>
            <Route path="subscription" element={<EventSubscription />} />
            <Route element={<SubscribedRoutes />}>
              <Route path="dashboard" element={<Home />} />
              {/* <Route path="*" element={<Dashboard />} /> */}
              <Route path="customers" element={<Customer />} />
              <Route path="products" element={<EventProducts />} />
              <Route path="my-orders" element={<Orders />} />
              <Route path="my-orders/:id" element={<EventEachOrder />} />
              <Route path="wallet" element={<EventWallet />} />
              <Route path="settings" element={<WithdrawalSettings />} />
              <Route path="product/:id" element={<SingleProduct />} />
            </Route>
          </Route>
        </Route>

        <Route path="/forgot-password" exact element={<EventForgot />} />
        <Route path="/reset-password/:token" element={<EventReset />} />
        <Route path="/select-plans" element={<Plans />} />
        <Route path="/verification" element={<VerificationPage />} />
        {/* <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Login />} /> */}
        <Route path="/login" element={<SignIn />} />
        <Route path="/signup" element={<Register />} />
        {/* <Route path="*" element={<h1 className="h-screen text-center">Opps Page not Found</h1>}/> */}
      </Routes>
    </>
  );
};

export default AllRoutes;
