import axios from "axios";
const _tk =
  // eslint-disable-next-line valid-typeof
  typeof window !== undefined
    ? JSON.parse(sessionStorage.getItem("v_tk"))
    : null;

const BASE_URL = process.env.REACT_APP_BASEURL;
export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: _tk ? `Token ${_tk}` : "",
  },
});
