import React from "react";
import Skeleton from "react-loading-skeleton";

const Loader = () => {
  const mappedLoading = Array(10)
    .fill("a")
    .map((v, i) => (
      <div
        key={i}
        className={`m-auto w-full h-[50px] ${
          i % 2 === 0 ? "bg-[#e9eaf9]" : "bg-gray-50"
        }`}
      >
        <Skeleton width="100%" height="100%" />
      </div>
    ));
  return <div className="w-full">{mappedLoading}</div>;
};

export default Loader;
