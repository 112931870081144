import React, { useEffect, useRef, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import joinClasses from "../../utils/joinClasses";
import styles from "./style.module.css";
const ImageCarousel = ({ images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const carouselItemsRef = useRef([]);

  useEffect(() => {
    if (images && images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(
        0,
        images.length
      );

      setSelectedImageIndex(0);
      setSelectedImage(images[0]);
    }
  }, [images]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [selectedImage]);

  const handleSelectedImageChange = (newIdx) => {
    if (images && images.length > 0) {
      setSelectedImage(images[newIdx]);
      setSelectedImageIndex(newIdx);
      if (carouselItemsRef?.current[newIdx]) {
        carouselItemsRef?.current[newIdx]?.scrollIntoView({
          inline: "center",
          behavior: "smooth",
        });
      }
    }
    window.scrollTo({ top: 0 });
  };

  const handleRightClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx >= images.length) {
        newIdx = 0;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx < 0) {
        newIdx = images.length - 1;
      }
      handleSelectedImageChange(newIdx);
    }
  };
  return (
    <div className=" w-full xl:mx-0 mx-auto max-w-[610px]">
      <div className={joinClasses(styles["carousel-container"])}>
        <div
          className="w-full md:h-[617px] h-[252px]"
          style={{
            backgroundImage: `url(${selectedImage?.image})`,
            backgroundSize: "cover",
          }}
        />
        <div className="carousel">
          <div className={joinClasses(" relative", styles.carousel__images)}>
            {images &&
              images.map((image, idx) => (
                <>
                  <div
                    onClick={() => handleSelectedImageChange(idx)}
                    style={{ backgroundImage: `url(${image.image})` }}
                    key={image.id}
                    className={`${styles.carousel__image} ${
                      selectedImageIndex === idx &&
                      styles["carousel__image-selected"]
                    }`}
                    ref={(el) => (carouselItemsRef.current[idx] = el)}
                    alt="text"
                  />
                </>
              ))}
          </div>
          <button
            className={joinClasses(
              "bg-[#E1E1E1] h-[30px] w-[30px] rounded-full carousel__button-left ",
              styles.carousel__button,
              styles["carousel__button-left"]
            )}
            onClick={handleLeftClick}
          >
            <FaChevronLeft className="m-auto font-bold " />
          </button>
          <button
            className={joinClasses(
              "bg-[#E1E1E1] h-[30px] w-[30px] rounded-full",
              styles.carousel__button,
              styles["carousel__button-right"]
            )}
            onClick={handleRightClick}
          >
            <FaChevronLeft className="m-auto rotate-180 font-bold" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
