import React from 'react'
import Classes from "./button.module.css"
import Loading from '../loading/loading';


const Button = ({disabled, loading, onClick, style, text, classNames, showLoading=true}) => {
    return (
        <button disabled={loading||disabled} onClick={onClick} style={{...style}} className={`${Classes.button}   ${classNames}`}>
            <div>{text}</div>
            {showLoading && <>{loading && (
              <Loading
                size="1.5em"
                fill="#fff"
                style={{margin: '20px 0px 0px 20px'}}
              />
            )}</>}
        </button>
    )
}

export default Button
