// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eachPageHeader_eachPageHeaderCover__-WlBs{\n    display: flex;\nfont-size: 13px;\nfill: #7F7F7F;\nheight: 20px;\nalign-items: center;\nwidth: 200px;\njustify-content: space-between;\ncolor: #7F7F7F;\n}\n\n.eachPageHeader_eachPageHeaderCover__-WlBs small:last-child{\n    color: #ff0066;\n\n}\n\n.eachPageHeader_eachPageHeader__hMfp2{\ncolor: #7F7F7F;\n\n}", "",{"version":3,"sources":["webpack://./src/component/layout/eachPageHeader/eachPageHeader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB,eAAe;AACf,aAAa;AACb,YAAY;AACZ,mBAAmB;AACnB,YAAY;AACZ,8BAA8B;AAC9B,cAAc;AACd;;AAEA;IACI,cAAc;;AAElB;;AAEA;AACA,cAAc;;AAEd","sourcesContent":[".eachPageHeaderCover{\n    display: flex;\nfont-size: 13px;\nfill: #7F7F7F;\nheight: 20px;\nalign-items: center;\nwidth: 200px;\njustify-content: space-between;\ncolor: #7F7F7F;\n}\n\n.eachPageHeaderCover small:last-child{\n    color: #ff0066;\n\n}\n\n.eachPageHeader{\ncolor: #7F7F7F;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eachPageHeaderCover": "eachPageHeader_eachPageHeaderCover__-WlBs",
	"eachPageHeader": "eachPageHeader_eachPageHeader__hMfp2"
};
export default ___CSS_LOADER_EXPORT___;
