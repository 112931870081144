import React, { useState, useEffect, useRef, useContext } from "react";
import toast from "react-hot-toast";
import { GlobalContext } from "../../context/GlobalProvider";
import Products from "./products";
import Loader from "../../component/loader";

const EventProducts = () => {
  const [itemsPerPage, setItemsPerPage] = useState("10");

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [category, setCategory] = useState([]);
  const msg = "";
  const [searchInput, setSearchInput] = useState("");
  const [clicked, setClicked] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState();
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;

  const refReactPaginate = useRef();
  const { storeStatistics } = useContext(GlobalContext);

  const getAllProducts = async () => {
    try {
      setLoading(true);

      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/products`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );
      const { status, data } = await fetched.json();

      if (status !== 200) throw new Error("Opps something went wrong");
      setLoading(false);
      setProducts(data);
      setCurrentItems(data);
    } catch (e) {
      setLoading(false);
      console.log(e.message);
    }
  };

  const getAllCategory = async () => {
    try {
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/customer/categories`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );
      const json = await fetched.json();
      setCategory(json.results);
    } catch (error) {
      console.log(error);
      console.log(error);
    }
    // setLoading(false);
    // setProducts(json.data);
    setHydration(true);
  };
  const [hydration, setHydration] = useState(false);
  useEffect(() => {
    getAllCategory();
    getAllProducts();
  }, []);

  useEffect(() => {
    if (products.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = products.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(products.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, products]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % products.length;
    setItemOffset(newOffset);
  };

  // deleting any perticular product with the id
  const deleteProductHandler = async () => {
    try {
      setLoading(true);
      const request = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/product/delete/${clicked.pk}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );
      const { data, status } = await request.json();
      if (status !== 200) throw new Error("Opps something went wrong");
      setLoading(false);
      storeStatistics();
      toast(data);
      getAllProducts();
      setShowDeleteModal(false);
    } catch (e) {
      setLoading(false);
      toast(e.message);
    }
  };

  const productVisibility = async (pk, stock) => {
    try {
      setLoading(true);
      const request = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/product/status/${pk}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
          body: JSON.stringify({
            status: stock,
          }),
        }
      );
      const { data, status } = await request.json();
      if (status !== 200) throw new Error("Opps something went wrong");
      setLoading(false);
      storeStatistics();
      toast(data);
      getAllProducts();
      setShowDeleteModal(false);
    } catch (e) {
      setLoading(false);
      toast(e.message);
    }
  };

  const onChangeHandler = (e) => {
    setItemsPerPage(e.target.value.split(" ")[1]);
  };

  if (hydration && !loading) {
    return (
      <Products
        showModal={showModal}
        setShowModal={setShowModal}
        loading={loading}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        currentItems={currentItems}
        products={products}
        productVisibility={productVisibility}
        pageNum={pageNum}
        refReactPaginate={refReactPaginate}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        category={category}
        reGetProducts={getAllProducts}
        deleteProduct={deleteProductHandler}
        msg={msg}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        clicked={clicked}
        setClicked={setClicked}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        onChangeHandler={onChangeHandler}
        reloadProduct={() => setLoading(true)}
        stopLoading={() => setLoading(false)}
      />
    );
  } else {
    return (
      <h1>
        <Loader />
      </h1>
    );
  }
};

export default EventProducts;
