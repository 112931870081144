import React, { useContext, useState, useEffect } from "react";

import { Modal } from "../../component/modal/modal";
import Input from "../../component/input/input";
import Button from "../../component/button/button";
import Skeleton from "react-loading-skeleton";
import Classes from "./withdrawalSettings.module.css";
import toast from "react-hot-toast";
import { MainContext } from "../../App";

const ChangeAgent = ({
  showAgent,
  closeAgent,
  setRequestAgent,
  getAgentDetail,
}) => {
  const [agents, setAgents] = useState([]);
  const CTX = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState(false);
  const [text, setText] = useState("");
  const selected = {};
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;
  const getAllAgents = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/setting/agents`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );
      const { data, status } = await response.json();

      if (status !== 200) throw new Error("Opps something went wrong");
      setLoading(false);
      setAgents(data);
    } catch (e) {
      setLoading(false);
      toast(e.message);
    }
  };

  useEffect(() => {
    getAllAgents();
  }, []);
  // {first_name:"Naruto",last_name:'Uzumaki',state:'Abua',phone:'387483648368'}
  const mappedAgents = agents.map((v, i) => (
    <div key={i} className={`mt-5 p-2 space-y-3 ${Classes.mappedOrders}`}>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>
          Agent: {v.first_name} {v.last_name}
        </strong>
      </div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Location: {v.state}</strong>
      </div>

      <div className="flex capitalize font-bold justify-between pt-2 gap-2">
        <button
          onClick={() => {
            setRequestAgent((prev) => !prev);
            closeAgent();
            getAgentDetail({ pk: v.pk });
          }}
          className="p-1 px-5 w-full text-white rounded-xl bg-[#ff0066]"
        >
          Request
        </button>
      </div>
      {/* <div className={Classes.myOrderItemCover}>
        <button
          onClick={() => {
            setReason(true);
            setSelected(v);
          }}
          className={Classes.trueVisibilityCover}
        >
          Select Agent
        </button>
      </div> */}
    </div>
  ));

  // console.log("selected => ", selected);

  const mappedLoading = Array(5)
    .fill("s")
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  const requestAgentHandler = async () => {
    try {
      setLoading(true);
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/setting/agent/change`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${CTX.token}`,
          },
          body: JSON.stringify({
            agent: selected.pk,
            reason: text,
          }),
        }
      );
      const json = await fetched.json();
      setReason(false);
      setLoading(false);
      closeAgent();
      console.log("json => ", json);
      console.log("json => ", json);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      className={"w-full lg:max-w-[898px] max-w-[400px]"}
      show={showAgent}
      close={closeAgent}
    >
      <div className={Classes.headerSection}>
        <strong>{reason ? "Request New Agent" : "All Agents"}</strong>
      </div>

      {!reason && (
        <div className={Classes.cardCover}>
          <div className={Classes.mappedStoresCover}>
            <div className="h-[384px] shadow-lg  overflow-auto">
              {loading ? (
                mappedLoading
              ) : agents.length > 0 ? (
                <div className="lg:hidden">{mappedAgents}</div>
              ) : (
                <div className="h-full flex">
                  <p className="text-center m-auto font-bold">
                    There is no agent currently in your location
                  </p>
                </div>
              )}

              <table className="w-full hidden lg:table">
                {agents.length > 0 && (
                  <thead className="bg-[#00000026] text-[#444445] ">
                    <th className="py-4">Agent Name</th>
                    <th>Location</th>
                    <th>Action</th>
                  </thead>
                )}

                <tbody>
                  {agents.map(({ first_name, last_name, state, pk }, index) => (
                    <tr
                      className={`text-center ${index % 2 && "bg-[#E9EAF9]"} `}
                      key={pk}
                    >
                      <td className="w-[25%] border-r py-5">
                        {first_name} {last_name}
                      </td>
                      <td className="w-[25%] border-r">{state}</td>
                      <td className="w-[25%] ">
                        <div className=" capitalize font-bold  space-x-2">
                          <button
                            onClick={() => {
                              setRequestAgent((prev) => !prev);
                              closeAgent();
                              getAgentDetail({ pk });
                            }}
                            className="p-1 px-5  text-white rounded-xl bg-[#ff0066]"
                          >
                            Request
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {reason && (
        <>
          {" "}
          <div
            style={{ paddingBottom: "0px" }}
            className={Classes.subHeaderSection}
          >
            <Input
              txtPlaceholder=" "
              label="Add a reason for the request:"
              type="textarea"
              inputType="password"
              onChange={(e) => setText(e.target.value)}
              value={text}
            />
          </div>
          <div className={Classes.bottomBorder}>
            <div style={{ marginLeft: "auto", display: "flex" }}>
              <Button
                bgColor="#ff0166"
                style={{
                  backgroundColor: "rgb(0, 0, 51)",
                  borderColor: "rgb(0, 0, 51)",
                }}
                onClick={() => setReason(false)}
                text="Back"
              />
              <div style={{ width: "20px" }}> </div>
              <Button
                loading={loading}
                style={{ height: "38px" }}
                bgColor="#6c757d"
                text="Yes, Request"
                onClick={requestAgentHandler}
              />
            </div>
          </div>{" "}
        </>
      )}
    </Modal>
  );
};

export default ChangeAgent;
