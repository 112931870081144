// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading_loadingSVG__B9vou{\n    animation: 1.5s linear infinite loading_spinner__rbLlz;\n    will-change: transform;\n    animation-play-state: inherit;\n}\n\n@keyframes loading_spinner__rbLlz {\n    0% {\n      transform: translate3d(-50%, -50%, 0) rotate(0deg);\n    }\n    100% {\n      transform: translate3d(-50%, -50%, 0) rotate(360deg);\n    }\n  }", "",{"version":3,"sources":["webpack://./src/component/loading/loading.module.css"],"names":[],"mappings":"AAAA;IACI,sDAAuC;IACvC,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI;MACE,kDAAkD;IACpD;IACA;MACE,oDAAoD;IACtD;EACF","sourcesContent":[".loadingSVG{\n    animation: 1.5s linear infinite spinner;\n    will-change: transform;\n    animation-play-state: inherit;\n}\n\n@keyframes spinner {\n    0% {\n      transform: translate3d(-50%, -50%, 0) rotate(0deg);\n    }\n    100% {\n      transform: translate3d(-50%, -50%, 0) rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSVG": "loading_loadingSVG__B9vou",
	"spinner": "loading_spinner__rbLlz"
};
export default ___CSS_LOADER_EXPORT___;
