import React, { Suspense, useState, useEffect } from "react";
import Classes from "./wallet.module.css";
import DashboardCard from "../../component/dashboardCard/dashboardCard";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import { AiOutlineSearch } from "react-icons/ai";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";
import { ReactComponent as Money } from "../../asset/money.svg";
// import {ReactComponent as Store} from '../../asset/store.svg';
import { ReactComponent as Box } from "../../asset/box.svg";
// import {ReactComponent as Bag} from '../../asset/bag.svg';
// import {ReactComponent as Left} from '../../asset/left.svg';
import { AiOutlineWallet } from "react-icons/ai";
import Button from "../../component/button/button";
import { Months } from "../../data/monthsJson";
import ReactPaginate from "react-paginate";
import { ReactComponent as Left } from "../../asset/left.svg";
import ModalSection from "./modalSection";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import { GetUserStatistics } from "../../helper/getUserStatistics";

const Wallet = ({
  setShowModal,
  currentItems,
  selectOnChangeHandler,
  pageCount,
  onChangeHandler,
  monthly_income,
  refReactPaginate,
  handlePageClick,
  loading,
  reloadPage,
  showModal,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const { data, getSta } = GetUserStatistics();

  const mappedTransaction = currentItems
    .filter((v) =>
      v.reference.toLowerCase().includes(searchInput.toLowerCase())
    )
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Reference: </strong>
          {v.reference}
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Price: </strong>₦{v.amount}
        </div>

        <div className={Classes.myOrderItemCover}>
          {/* {v.status ? (
          <button className={Classes.trueVisibilityCover}>paid</button>
        ) : ( */}
          <button
            style={{ backgroundColor: "#ff6251" }}
            className={Classes.trueVisibilityCover}
          >
            {v.status}
          </button>
          {/* )} */}
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Date: </strong>

          {v.created.split("T")[0]}
        </div>
      </div>
    ));

  const mappedLoading = Array(5)
    .fill("s")
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  useEffect(() => {
    getSta();
  }, []);

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Wallet - Passward Vendor </title>
        <meta name="og:title" content="Wallet - Passward Vendor" />
        <meta name="og:description" content="Wallet - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className="h-screen w-full p-4">
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Wallet" />
          <div className={Classes.gridDashboardCard}>
            <DashboardCard
              label="Wallet Balance"
              num={
                "₦" + " " + `${data.balance === undefined ? "0" : data.balance}`
              }
              svg={<AiOutlineWallet />}
            />
            <DashboardCard
              label="Total Revenue"
              num={
                "₦" + " " + `${data.revenue === undefined ? "0" : data.revenue}`
              }
              svg={<Box width="16px" />}
            />
            <div className={Classes.DashboardCardCover}>
              <div className={Classes.svgCover}>
                <Money />
              </div>
              <div className={Classes.textCoverSection}>
                <select
                  onChange={(e) => selectOnChangeHandler(e.target.value)}
                  className={Classes.selectRevenue}
                >
                  <option>All Revenue</option>
                  {Months.map((v, i) => (
                    <option key={i}>{v.name}</option>
                  ))}
                </select>

                <strong style={{ fontSize: "13px" }}>
                  {monthly_income?.monthly_income?.amount}
                </strong>
              </div>
            </div>
          </div>

          <div className={Classes.subNavCover}>
            <form className={Classes.firstForm}>
              <input
                placeholder="Search Wallet"
                onChange={(e) => setSearchInput(e.target.value)}
                className={Classes.searchFormSection}
              />
              <button className={Classes.buttonFormSection}>
                <AiOutlineSearch fill="#fff" size="1.3em" />
              </button>
            </form>
            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>Wallet Transaction</h3>
              <div className={Classes.sideEachPageHeader}>
                <form className={Classes.secondForm}>
                  <input
                    placeholder="Search  Wallet"
                    onChange={(e) => setSearchInput(e.target.value)}
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
                <Button
                  onClick={() => setShowModal(true)}
                  text="Withdraw Fund"
                  style={{ height: "max-content", marginTop: "0px" }}
                />
              </div>
            </div>
          </div>

          {/* main order sectioon where the orders are mapped */}
          <div className={Classes.cardCover}>
            <div className={Classes.mappedStoresCover}>
              <div className={Classes.cardListingCover}>
                {!mappedTransaction.length < 1 && (
                  <div className={Classes.cardHeader}>
                    <strong>Reference</strong>
                    <strong>Price</strong>
                    <strong>Status</strong>
                    <strong>Date</strong>
                  </div>
                )}

                {loading ? (
                  mappedLoading
                ) : mappedTransaction.length > 0 ? (
                  mappedTransaction
                ) : (
                  <div className={Classes.noProducts}>
                    <p>You do not have any wallet history</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!mappedTransaction.length < 1 && (
            <div
              style={{ display: "flex", marginBottom: "30px", width: "100%" }}
            >
              <div className={Classes.showingCover}>
                <select
                  onChange={onChangeHandler}
                  className={Classes.showingSelectCover}
                >
                  <option>Showing 1</option>
                  <option defaultValue selected>
                    Showing 10
                  </option>
                  <option>Showing 25</option>
                  <option>Showing 50</option>
                  <option>Showing 100</option>
                </select>
              </div>

              <ReactPaginate
                ref={refReactPaginate}
                breakLabel="..."
                nextLabel={<Left style={{ transform: "rotate(180deg)" }} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<Left />}
                className={Classes.paginationClass}
                renderOnZeroPageCount={null}
                disabledClassName={Classes.disabledClassName}
              />
            </div>
          )}
        </Suspense>
      </div>
      <ModalSection
        showModal={showModal}
        setShowModal={setShowModal}
        reloadPage={reloadPage}
      />
    </div>
  );
};

export default Wallet;
