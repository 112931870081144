import React from "react";
import Classes from "./login.module.css";
import Input from "../../component/input/input";
import { useState } from "react";
import Button from "../../component/button/button";
import useLoginMutation from "../../api/auth/login";
import SemiLogo from "../../asset/logo_semi.png";
import RightLogo from "../../asset/star.png";
import { Link, useNavigate } from "react-router-dom";
const SignIn = () => {
  // Input state
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  // Password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Regular expression
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

  //login request
  const { mutate, isLoading } = useLoginMutation();
  // Submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(inputs);
  };
  // Password Error message state
  const [showPassMSG, setShowPassMSG] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={Classes.LoginFormCover}>
      <div className="h-full w-full flex ">
        <div className="w-full max-w-md m-auto ">
          <header className="m-auto text-black font-bold text-3xl text-center mb-5">
            Login
          </header>
          <form
            onSubmit={handleSubmit}
            className={`w-full shadow-xl p-5 pt-[83px] pb-10 rounded-lg relative z-20`}
          >
            {/* Email input */}
            <Input
              type="input"
              placeholder="yourmail@domain.com"
              inputType="email"
              value={inputs?.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
              required={true}
              labelStyle={{ color: "#000033" }}
              label="Email"
              style={{
                backgroundColor: "#fff",
                height: "50px",
                border: "1px solid #ff0066",
              }}
            />
            {/* Password input */}
            <Input
              type="input"
              required={true}
              value={inputs?.password}
              onChange={(e) => {
                setInputs({ ...inputs, password: e.target.value });
                if (re.test(e.target.value)) {
                  return setShowPassMSG(false);
                } else {
                  return setShowPassMSG(true);
                }
              }}
              labelStyle={{ color: "#000033" }}
              placeholder="* * * * * * *"
              showPassword={() => setShowPassword(!showPassword)}
              inputType={showPassword ? "text" : "password"}
              label="Password"
              style={{
                backgroundColor: "#fff",
                height: "50px",
                border: "1px solid #ff0066",
              }}
            />
            {/* Error Message Input */}
            <>
              {showPassMSG && (
                <>
                  {inputs?.password?.length > 1 && (
                    <p className={Classes.must_Contain}>
                      Password must contain an Uppercase, Lowercase and Symbol
                    </p>
                  )}
                </>
              )}
            </>
            {/* Keep me signed in checkbox */}
            <div
              className={`!w-full ${Classes.main_width} pb-5`}
              style={{ marginTop: "8px", flexDirection: "row" }}
            >
              <div>
                <input type="checkbox" className={Classes.inputCheckbox} />
                <label className={Classes.coloredLabel}>
                  Keep me Signed In
                </label>
              </div>
              <Link
                style={{
                  marginLeft: "auto",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to="/forgot-password"
              >
                <label
                  className={Classes.coloredLabel}
                  style={{ cursor: "pointer" }}
                >
                  Forgot Password?
                </label>
              </Link>
            </div>
            {/* Login button */}
            <Button
              text={"Login"}
              loading={isLoading}
              style={{ width: "100%", height: "40px" }}
            />
            {/* Don't have an account section */}
            <div className="mt-5 text-center">
              Don’t have an account?
              <span
                style={{
                  marginLeft: "5px",
                  color: "#FF0066",
                  textDecoration: "underline",
                  cursor: "pointer",

                  zIndex: 20,
                }}
                onClick={() => navigate("/signup")}
              >
                Register
              </span>
            </div>
          </form>
        </div>
      </div>

      {/* Stars images */}
      <img src={SemiLogo} className={Classes.topRightLogo} alt="img" />
      <img src={RightLogo} className={Classes.rightLogo} alt="img" />
      <img src={RightLogo} className={Classes.leftLogo} alt="img" />
      <img src={RightLogo} className={Classes.topLeftLogo} alt="img" />
      {/* <img src={SemiLogo} className={Classes.semiLogo} alt="img" /> */}
    </div>
  );
};

export default SignIn;
