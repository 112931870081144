import React, { Suspense, useState } from "react";
import Classes from "./subscription.module.css";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";
import { AiOutlineSearch } from "react-icons/ai";
import Button from "../../component/button/button";

import ReactPaginate from "react-paginate";
import { ReactComponent as Left } from "../../asset/left.svg";
import ModalSection from "./modalSection";
import { Helmet } from "react-helmet";
import Loader from "../../component/loader";

const Subscription = ({
  setShowModal,
  currentItems,
  onChangeHandler,
  loading,
  showModal,
  pageCount,
  refReactPaginate,
  subscriptionPlan,
  handlePageClick,
  refetchSubscription,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const mappedSub = currentItems
    .filter((v) =>
      v.reference.toLowerCase().includes(searchInput.toLowerCase())
    )
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}> Reference ID: </strong>
          {v.reference}
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Plan Name: </strong>
          {v.plan_name}
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Plan Type: </strong>
          {v.plan_type}
        </div>
        <div className={Classes.myOrderItemCover}>₦{v.amount}</div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Subscription Date: </strong>
          {v.subscribed.split("T")[0]}
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Expiry Date: </strong>
          {v.expired.split("T")[0]}
        </div>

        {/* </div> */}
      </div>
    ));

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Subscription - Passward Vendor </title>
        <meta name="og:title" content="Subscription - Passward Vendor" />
        <meta name="og:description" content="Subscription - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className="h-screen w-full p-4">
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Subscription" />
          <div className={Classes.subNavCover}>
            <form className={Classes.firstForm}>
              <input
                placeholder="Search..."
                className={Classes.searchFormSection}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button className={Classes.buttonFormSection}>
                <AiOutlineSearch fill="#fff" size="1.3em" />
              </button>
            </form>

            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>My Subscription</h3>
              <div className={Classes.sideEachPageHeader}>
                <form className={Classes.secondForm}>
                  <input
                    placeholder="Search..."
                    className={Classes.searchFormSection}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  text="Change Subscription Plan"
                  style={{ height: "max-content", marginTop: "0px" }}
                />
              </div>
            </div>
          </div>

          {/* here is the listing of all the products with the headers */}
          <div className={Classes.cardCover}>
            <div className={Classes.mappedStoresCover}>
              <div className={Classes.cardListingCover}>
                {!mappedSub.length < 1 && (
                  <div className={Classes.cardHeader}>
                    <strong>Reference ID</strong>
                    <strong>Plan Name</strong>
                    <strong>Plan Type</strong>
                    <strong>Amount</strong>
                    <strong>Subscription Date</strong>
                    <strong>Expiry Date</strong>
                  </div>
                )}

                {loading ? (
                  <Loader />
                ) : mappedSub.length > 0 ? (
                  mappedSub
                ) : (
                  <div className={Classes.msgCover}>
                    You have not made any subscription
                  </div>
                )}
              </div>
            </div>
          </div>

          {!mappedSub.length < 1 && (
            <div
              style={{ display: "flex", marginBottom: "30px", width: "100%" }}
            >
              <div className={Classes.showingCover}>
                <select
                  onChange={onChangeHandler}
                  className={Classes.showingSelectCover}
                >
                  <option>Showing 1</option>
                  <option defaultValue selected>
                    Showing 10
                  </option>
                  <option>Showing 25</option>
                  <option>Showing 50</option>
                  <option>Showing 100</option>
                </select>
              </div>

              <ReactPaginate
                ref={refReactPaginate}
                breakLabel="..."
                nextLabel={<Left style={{ transform: "rotate(180deg)" }} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<Left />}
                className={Classes.paginationClass}
                renderOnZeroPageCount={null}
                disabledClassName={Classes.disabledClassName}
              />
            </div>
          )}
        </Suspense>
      </div>
      <ModalSection
        currentItems={currentItems}
        showModal={showModal}
        setShowModal={setShowModal}
        subscriptionPlan={subscriptionPlan}
        refetchSubscription={refetchSubscription}
      />
    </div>
  );
};

export default Subscription;
