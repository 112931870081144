import React, { Suspense, useContext, useEffect, useState } from "react";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";
import Classes from "./customer.module.css";
import Button from "../../component/button/button";
import { AiOutlineSearch } from "react-icons/ai";

import Skeleton from "react-loading-skeleton";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LazyLoading from "../../component/lazyLoading/lazyLoading";

import ReactPaginate from "react-paginate";
import { ReactComponent as Left } from "../../asset/left.svg";
import { Helmet } from "react-helmet";
import MessageCustomer from "./messageCustomer";
import toast from "react-hot-toast";
import { GlobalContext } from "../../context/GlobalProvider";

const Customer = ({
  loading,
  showModal,
  setShowModal,
  refReactPaginate,
  handlePageClick,
  pageCount,
  onChangeHandler,
  searchInput,
  setSearchInput,
  allCustomers,
  setAllCustomers,
}) => {
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;
  const CTX = useContext(GlobalContext);
  const [customers, setCustomers] = useState([]);
  const [checkSub, setCheckSub] = useState(0);
  const onChangeCheckBox = async (pk) => {
    let spread = await [...customers];
    const checkIf = await spread.filter((v) => v === pk);
    const findIndex = await spread.findIndex((v) => v === pk);
    // console.log("index => ", findIndex);
    // console.log("index => ", findIndex);
    if (checkIf.length > 0) {
      // remove the id
      await spread.splice(findIndex, 1);
      return setCustomers(spread);
    } else {
      spread.push(pk);
      setCustomers(spread);
      return;
    }
  };
  const selectedCustomer = allCustomers
    .filter((item) => item.selected)
    .map((item) => item.pk);
  const mapMyOrders = allCustomers
    .filter((v) => v.email.toLowerCase().includes(searchInput.toLowerCase()))
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <div className={`${Classes.inputCoverHere} px-5 md:px-0`}>
          <input
            disabled={!v.sms}
            type="checkbox"
            checked={v?.selected}
            value={v?.selected}
            className={`mr-auto md:mx-auto`}
            onChange={() => {
              onChangeCheckBox.bind(this, i);
              setAllCustomers((prev) =>
                prev.map((item) =>
                  item.pk === v.pk
                    ? { ...item, selected: !item.selected }
                    : item
                )
              );
            }}
          />
        </div>
        {/* <input
            disabled={!v.sms}
            type="checkbox"
            onChange={onChangeCheckBox.bind(this, i)}
            className="absolute top-[5px] left-[5px]"
          /> */}
        <div
          className={`md:w-full md:pl-0 pl-[20px] ${Classes.myOrderItemCover}`}
        >
          <strong className={Classes.strongSection}>Name: </strong>
          {v.first_name + " " + v.last_name}
        </div>
        <div
          className={`md:w-full md:pl-0 pl-[20px] ${Classes.myOrderItemCover}`}
        >
          <strong className={Classes.strongSection}>Email: </strong>
          {v.state}
        </div>

        <div
          className={` md:w-full ${Classes.myOrderItemCover} md:pl-0 pl-[20px]`}
        >
          <strong className={`${Classes.strongSection}`}>Quantity: </strong>
          <span className="">{v.order_stat.qty}</span>
        </div>

        <div
          className={`md:w-full md:pl-0 pl-[20px] mt-2 ${Classes.myOrderItemCover}`}
        >
          {" "}
          <span className="md:hidden">Amount :</span> {v.order_stat.revenue}
        </div>

        <div
          className={`md:w-full md:pl-0 pl-[20px] ${Classes.myOrderItemCover}`}
        >
          {v.sms ? "Enabled" : "Disabled"}
        </div>
      </div>
    ));

  const mapLoading = Array(7)
    .fill("")
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  const getSubscriptionPlan = async () => {
    if (_tk) {
      try {
        const fetched = await fetch(
          `${process.env.REACT_APP_BASEURL}/vendor/subscription/plans`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${_tk}`,
            },
          }
        );
        const json = await fetched.json();
        if (json.status !== 200) throw new Error("Opps something went wrong");
        setCheckSub(json.data.length);
      } catch (e) {
        toast(e.message);
        setCheckSub(0);
      }
    }
  };
  useEffect(() => {
    CTX.storeUserDetails(_tk);
    getSubscriptionPlan();
  }, []);
  // DONE: activation status is false and it disables the message customer button
  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Customers - Passward Vendor </title>
        <meta name="og:title" content="Customers - Passward Vendor" />
        <meta name="og:description" content="Customers - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>

      <div className="h-screen w-full p-4">
        <Suspense fallback={<LazyLoading />}>
          <div className={Classes.myOrdersCover}>
            <EachPageHeader first="Home" second="Customers" />
            {checkSub > 0 && (
              <div className={Classes.subNavCover}>
                <form className={Classes.firstForm}>
                  <input
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Search  customer name"
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>

                <div className={Classes.PageHeaderCover}>
                  <h3 className={Classes.eachPageHeader}>
                    Customers:{" "}
                    {!!CTX?.user && `${CTX?.user?.sms_credit} units left`}
                  </h3>
                  <div
                    className={Classes.sideEachPageHeader}
                    style={{ width: "max-content" }}
                  >
                    <form className={Classes.secondForm}>
                      <input
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        placeholder="Search  customer email"
                        className={Classes.searchFormSection}
                      />
                      <button className={Classes.buttonFormSection}>
                        <AiOutlineSearch fill="#fff" size="1.3em" />
                      </button>
                    </form>
                    {CTX.user?.store_detail?.activation_status && (
                      <Button
                        classNames={`h-full ${
                          customers.length === 0 && "bg-gray-500"
                        }`}
                        style={{
                          border: "none",
                        }}
                        onClick={() => setShowModal(true)}
                        text="Message Customers"
                        disabled={mapMyOrders.length === 0}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* here is the listing of all the customers with the headers */}
            <div className={Classes.cardCover}>
              {!!allCustomers?.length && (
                <div className="space-x-5">
                  <label htmlFor="" className={Classes.eachPageHeader}>
                    Select all customers
                  </label>
                  <input
                    type="checkbox"
                    checked={allCustomers.every(
                      (customer) => customer.selected
                    )}
                    // className={`mr-auto md:mx-auto`}
                    onChange={(e) => {
                      setAllCustomers((prev) =>
                        prev.map((item) => ({
                          ...item,
                          selected: e.target.checked,
                        }))
                      );
                    }}
                  />
                </div>
              )}

              <div className={Classes.mappedStoresCover}>
                <div className={Classes.cardListingCover}>
                  {!mapMyOrders.length < 1 && (
                    <div className={Classes.cardHeader}>
                      <div></div>
                      <strong style={{ marginRight: "auto" }}>Name</strong>
                      <strong style={{ marginInline: "auto" }}>State</strong>
                      <strong>Qty</strong>
                      <strong>Revenue</strong>
                      <strong>Notification</strong>
                    </div>
                  )}

                  {/* condition for loading component and when loadined */}

                  {loading ? (
                    mapLoading
                  ) : mapMyOrders.length > 0 ? (
                    mapMyOrders
                  ) : (
                    <div className={Classes.noProducts}>
                      <p>You do not have any customers currently</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Suspense>

        {!mapMyOrders.length < 1 && (
          <div style={{ display: "flex", marginBottom: "30px", width: "100%" }}>
            <div className={Classes.showingCover}>
              <select
                onChange={onChangeHandler}
                className={Classes.showingSelectCover}
              >
                <option>Showing 1</option>
                <option defaultValue selected>
                  Showing 10
                </option>
                <option>Showing 25</option>
                <option>Showing 50</option>
                <option>Showing 100</option>
              </select>
            </div>

            {/*` */}
            <ReactPaginate
              ref={refReactPaginate}
              breakLabel="..."
              nextLabel={<Left style={{ transform: "rotate(180deg)" }} />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<Left />}
              className={Classes.paginationClass}
              renderOnZeroPageCount={null}
              disabledClassName={Classes.disabledClassName}
            />
          </div>
        )}
      </div>
      <MessageCustomer
        setAllCustomers={setAllCustomers}
        showModal={showModal}
        setShowModal={setShowModal}
        loading={loading}
        selectedCustomer={selectedCustomer}
      />
    </div>
  );
};

export default Customer;
