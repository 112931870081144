import React, { useContext, useState } from "react";

import { Modal } from "../../component/modal/modal";
import Input from "../../component/input/input";
import Button from "../../component/button/button";
import toast from "react-hot-toast";
import Classes from "./withdrawalSettings.module.css";
import { MainContext } from "../../App";
import axios from "axios";
import { GetUserDetails } from "../../helper/getUserHelper";

const ChangePass = ({
  show,
  close,

  showImage,
  closeImage,
}) => {
  const [inputs, setInputs] = useState({});
  const { getUser } = GetUserDetails();
  const [loading, setLoading] = useState(false);
  const [showExsPassword, setShowExsPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [msg, setMsg] = useState("");
  const [imgRef, setImgRef] = useState();
  const [showPasswordTxt, setShowPasswordTxt] = useState(false);
  const CTX = useContext(MainContext);
  const [showPassMSG, setShowPassMSG] = useState(false);
  const [showComPassMSG, setShowComPassMSG] = useState(false);
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

  const onSubmitPasswordHandler = async (e) => {
    e.preventDefault();
    console.log(inputs);
    if (inputs.newPassword !== inputs.conPassword) {
      setTimeout(() => {
        setMsg("");
      }, 5000);
      setMsg("Passwords do not match");
      return;
    }
    if (showPassMSG || showComPassMSG) {
      return;
    }
    setLoading(true);
    try {
      const request = await axios({
        url: `${process.env.REACT_APP_BASEURL}/vendor/setting/change/password`,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
        data: {
          old_password: inputs.password,
          new_password: inputs.newPassword,
          confirm_password: inputs.conPassword,
        },
      });
      console.log("request.data", request.data);
      setLoading(false);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      setMsg("Details saved successfully");
      toast("Details saved successfully");
      close();
      setInputs({ password: "", conPassword: "", newPassword: "" });
    } catch (error) {
      setLoading(false);

      if (error.response.data.data.password) {
        return toast(error.response.data.data.password);
      }

      console.log("error.response => ", error.response);

      return toast("Invalid details");
    }
  };

  // uploading the profile image
  const onBlurImageHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      setLoading(false);
      var formData = new FormData();
      formData.append(
        "store_logo",
        imgRef.target.files[Object.keys(imgRef.target.files)]
      );
      await axios({
        url: `${process.env.REACT_APP_BASEURL}/vendor/setting/store/logo/update`,
        data: formData,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
      });
      getUser();
      toast("Details saved successfully");
      closeImage();
    } catch (error) {
      setLoading(false);
      toast("Check your internet connection");
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        show={show}
        close={() => {
          close();
          setInputs({ password: "", conPassword: "", newPassword: "" });
        }}
      >
        <div className={Classes.headerSection}>
          <strong>Change Password</strong>
        </div>
        {msg.length > 0 && (
          <div
            className={Classes.msgAlert}
            style={{ textAlign: "center", marginBottom: "40px" }}
          >
            <p style={{ margin: "0px 0px" }} className={Classes.msgItself}>
              {msg}
            </p>
          </div>
        )}
        <form onSubmit={onSubmitPasswordHandler}>
          <div
            style={{ paddingBottom: "0px" }}
            className={Classes.subHeaderSection}
          >
            <Input
              required
              placeholder="Existing Password"
              label="Existing Password"
              type="input"
              value={inputs?.password}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  password: e.target.value,
                })
              }
              showPassword={() => setShowExsPassword(!showExsPassword)}
              inputType={showExsPassword ? "text" : "password"}
              style={{ height: "50px" }}
            />

            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <Input
                  required
                  placeholder="New Password"
                  label="Password"
                  type="input"
                  value={inputs?.newPassword}
                  onChange={(e) => {
                    setInputs({
                      ...inputs,
                      newPassword: e.target.value,
                    });
                    if (re.test(e.target.value)) {
                      return setShowPassMSG(false);
                    } else {
                      return setShowPassMSG(true);
                    }
                  }}
                  showPassword={() => setShowPasswordTxt(!showPasswordTxt)}
                  inputType={showPasswordTxt ? "text" : "password"}
                  style={{ height: "50px" }}
                />

                {showPassMSG && (
                  <>
                    {inputs?.newPassword?.length > 1 && (
                      <p className={Classes.must_Contain}>
                        Password must contain an uppercase, lowercase and symbol
                      </p>
                    )}
                  </>
                )}
              </div>

              <div style={{ width: "30px" }}> </div>
              <div style={{ width: "100%" }}>
                <Input
                  required
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  type="input"
                  value={inputs?.conPassword}
                  onChange={(e) => {
                    setInputs({
                      ...inputs,
                      conPassword: e.target.value,
                    });
                    if (e.target.value !== inputs?.newPassword) {
                      setShowComPassMSG(true);
                    } else {
                      setShowComPassMSG(false);
                    }
                  }}
                  style={{ height: "50px" }}
                  showPassword={() => setShowConPassword(!showConPassword)}
                  inputType={showConPassword ? "text" : "password"}
                />
                {showComPassMSG && (
                  <p className={Classes.must_Contain}>Passwords do not match</p>
                )}
              </div>
            </div>
          </div>

          <div className={Classes.bottomBorder}>
            <div style={{ marginLeft: "auto", display: "flex" }}>
              <Button
                bgColor="#ff0166"
                text="Save Password"
                loading={loading}
                showLoading={false}
              />
              <div style={{ width: "20px" }}> </div>
              {/* <Button bgColor="#6c757d" text="Cancel" /> */}
              <Button
                text="Cancel"
                onClick={() => {
                  close();
                  setInputs({ password: "", conPassword: "", newPassword: "" });
                }}
                style={{
                  marginLeft: "20px",
                  backgroundColor: "#fff",
                  color: "#ff0066",
                }}
              />
            </div>
          </div>
        </form>
      </Modal>

      <Modal show={showImage} close={closeImage}>
        <div className={Classes.headerSection}>
          <strong>Change Profile Image</strong>
        </div>
        <form onSubmit={onBlurImageHandler}>
          <div
            style={{ paddingBottom: "0px" }}
            className={Classes.subHeaderSection}
          >
            <Input
              txtPlaceholder=" "
              label="Select an image"
              type="input"
              accept="image/*"
              required
              inputType="file"
              onChange={(e) => setImgRef(e)}
              // onBlur={}
            />
          </div>

          <div className={Classes.bottomBorder}>
            <div style={{ marginLeft: "auto", display: "flex" }}>
              <Button
                bgColor="#6c757d"
                text="Change"
                style={{ height: "34px" }}
                loading={loading}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ChangePass;
