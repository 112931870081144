// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_mainBackgroundCover__nrXoT {\n    position: fixed;\n    width: 100%;\n    height: calc(100% + 100px);\n    z-index: 200;\n    background-color: #00003396;\n    top: -100px;\n    left: 0px;\n}\n\n.modal_mainModal__MDjp1 {\n    border-radius: 8px;\n    padding: 1.4em 1.4em 1.4em 1.4em;\n    /* height: 700px; */\n    box-sizing: border-box;\n    background-color: #fff;\n    position: fixed;\n    top: 50%;\n    max-height: 600px;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 1000;\n}\n\n.modal_AiOutlineClose__XpJmf {\n    fill: #7f7f7f;\n    width: max-content;\n    cursor: pointer;\n    margin-left: auto;\n    display: flex;\n    position: relative\n}", "",{"version":3,"sources":["webpack://./src/component/modal/modal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,0BAA0B;IAC1B,YAAY;IACZ,2BAA2B;IAC3B,WAAW;IACX,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,mBAAmB;IACnB,sBAAsB;IACtB,sBAAsB;IACtB,eAAe;IACf,QAAQ;IACR,iBAAiB;IACjB,SAAS;IACT,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb;AACJ","sourcesContent":[".mainBackgroundCover {\n    position: fixed;\n    width: 100%;\n    height: calc(100% + 100px);\n    z-index: 200;\n    background-color: #00003396;\n    top: -100px;\n    left: 0px;\n}\n\n.mainModal {\n    border-radius: 8px;\n    padding: 1.4em 1.4em 1.4em 1.4em;\n    /* height: 700px; */\n    box-sizing: border-box;\n    background-color: #fff;\n    position: fixed;\n    top: 50%;\n    max-height: 600px;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 1000;\n}\n\n.AiOutlineClose {\n    fill: #7f7f7f;\n    width: max-content;\n    cursor: pointer;\n    margin-left: auto;\n    display: flex;\n    position: relative\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainBackgroundCover": "modal_mainBackgroundCover__nrXoT",
	"mainModal": "modal_mainModal__MDjp1",
	"AiOutlineClose": "modal_AiOutlineClose__XpJmf"
};
export default ___CSS_LOADER_EXPORT___;
