import React, { useContext, useState } from "react";

import Input from "../../component/input/input";
import { Modal } from "../../component/modal/modal";
import Classes from "./wallet.module.css";
import Button from "../../component/button/button";
import { MainContext } from "../../App";
import { Banks } from "../../data/bankJson";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

const ModalSection = ({ sendMessageHandler, showModal, setShowModal }) => {
  const CTX = useContext(MainContext);
  const updateDetails = false;
  const confirm = false;
  const [loading, setLoading] = useState(false);
  const [withdrawalInput, setWithdrawalInput] = useState({
    ...CTX.user,
  });
  const [selectedBank, setSelectedBank] = useState(
    withdrawalInput?.bank || "Abbey Mortgage Bank"
  );

  //   useEffect(() => {
  //     if (!CTX.user.bank) {
  //       setUpdateDetails(true);
  //     } else {
  //       setUpdateDetails(false);
  //     }
  //   });

  const formOnSubmitHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      await axios({
        url: `${process.env.REACT_APP_BASEURL}/vendor/wallet/withdraw`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
        data: {
          amount: +withdrawalInput.amount,
          bank: selectedBank,
          bank_code: Banks.filter((v) => v.name === selectedBank)[0].code,
          account_no: withdrawalInput.account_no,
          account_name: withdrawalInput.account_name,
        },
      });
      // console.log("request => ", request)
      // console.log("request => ", request)
      setLoading(false);
      // reloadPage()
      window.location.reload();
      setShowModal(false);
      // toast('Details saved successfully');
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      setShowModal(false);
      if (error.response.data.message) {
        return toast(error.response.data.message);
      } else {
        return toast("Invalid details");
      }
    }
  };

  console.log(withdrawalInput);

  return (
    <Modal
      top="50%"
      show={showModal}
      showClose={confirm && false}
      className={"!max-w-[500px] !w-full"}
      close={() => setShowModal(!showModal)}
    >
      <h3 className={Classes.confirmRemoval}>
        {confirm ? "Confirm Withdrawal" : "Withdraw Fund"}
      </h3>
      {updateDetails && (
        <div className={Classes.cover}>
          <div className={Classes.updateDetailsCover}>
            <strong>Account details are required</strong>
            <small style={{ marginTop: "10px", textAlign: "center" }}>
              Go to withdrawal settings and add your account details.
            </small>
          </div>
          <Link to="/settings?type=account">
            <Button
              text="Update Details"
              style={{ margin: "30px auto 20px auto" }}
            />
          </Link>
        </div>
      )}
      {!confirm && (
        <form className={Classes.cover} onSubmit={formOnSubmitHandler}>
          <div className={Classes.amountCover}>
            <div className={Classes.nairaCover}>₦</div>
            <Input
              label="Amount"
              style={{ paddingLeft: "60px" }}
              required
              marb={true}
              type="input"
              inputType="number"
              value={withdrawalInput?.amount}
              onChange={(e) =>
                setWithdrawalInput({
                  ...withdrawalInput,
                  amount: e.target.value,
                })
              }
            />
          </div>
          <Input
            type="input"
            value={withdrawalInput?.bank}
            label="Bank"
            onChange={(e) => setSelectedBank(e.target.value)}
            disabled
          />
          <div className={Classes.gridTwo}>
            <Input
              required
              value={withdrawalInput?.account_name}
              onChange={(e) =>
                setWithdrawalInput({
                  ...withdrawalInput,
                  account_name: e.target.value,
                })
              }
              type="input"
              inputType="text"
              label="Account Name"
              disabled
            />
            <div>
              <Input
                required
                value={withdrawalInput?.account_no}
                onChange={(e) =>
                  setWithdrawalInput({
                    ...withdrawalInput,
                    account_no: e.target.value,
                  })
                }
                type="input"
                label="Account No"
                disabled
              />
              {withdrawalInput.account_no?.length !== 10 &&
                !!withdrawalInput.account_no && (
                  <p className="text-[10px] text-[#ff0066] text-right mb-2">
                    Account number should not be less or more than 10 digit
                  </p>
                )}
            </div>
          </div>

          <div className={Classes.flexRight}>
            <Button
              text="Withdraw"
              style={{ width: "130px", height: "38px" }}
              loading={loading}
              onClick={sendMessageHandler}
            />
            <Button
              text="Cancel"
              onClick={() => setShowModal(false)}
              style={{
                width: "130px",
                marginLeft: "20px",
                backgroundColor: "#fff",
                color: "#ff0066",
              }}
            />
          </div>
        </form>
      )}

      {confirm && (
        <>
          <div style={{ marginBottom: "20px" }} className={Classes.textSection}>
            Are you sure you want to send <strong>₦0</strong> to the account
            below
          </div>
          <div style={{ marginBottom: "7px" }} className={Classes.textSection}>
            Account name: <strong>Edet Mmenye Effiong</strong>
          </div>
          <div style={{ marginBottom: "7px" }} className={Classes.textSection}>
            Account number <strong>045143902</strong>
          </div>
          <div className={Classes.textSection}>
            Bank <strong>Guaranty Trust Bank</strong>
          </div>
          <div className={Classes.flexRight} style={{ marginTop: "10px" }}>
            <Button
              text="Yes, Withdraw"
              style={{ width: "150px" }}
              loading={loading}
              showLoading={false}
              onClick={sendMessageHandler}
            />
            <Button
              text="Cancel Withdraw"
              onClick={() => setShowModal(false)}
              style={{
                width: "150px",
                marginLeft: "20px",
                backgroundColor: "#fff",
                color: "#ff0066",
              }}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default ModalSection;
