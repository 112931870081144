import axios from "../../config/axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

const useRegisterMutation = () => {
  const registerVendor = async (context) => {
    try {
      const request = await axios.post(`vendor/register`, context);
      const { data } = request;

      return data.data;
    } catch (error) {
      if (error.response.data.message.slug) {
        toast(error.response.data.message.slug[0] ?? "Something went wrong");

        return error.response.data;
      } else {
        toast(error.response.data.message ?? "Something went wrong");

        return error.response.data;
      }
    }
  };

  return useMutation({
    mutationFn: registerVendor,
  });
};

export default useRegisterMutation;
