import React, { useRef, useState, useEffect } from "react";
import Subscription from "./subscription";

const EventSubscription = () => {
  const [itemsPerPage, setItemsPerPage] = useState("10");
  const [currentItems, setCurrentItems] = useState([]);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const pageNum = 1;
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const itemOffset = 0;
  const [showModal, setShowModal] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);
  const refReactPaginate = useRef();
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;

  const getAllSubscription = async () => {
    setLoading(true);
    const fetched = await fetch(
      `${process.env.REACT_APP_BASEURL}/vendor/subscriptions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
      }
    );
    const json = await fetched.json();
    // DONE: backend returning 0 array after subscribing
    console.log(json);
    setLoading(false);
    setSubscription(json.data);
  };

  const getSubscriptionPlan = async () => {
    setLoading(true);
    const fetched = await fetch(
      `${process.env.REACT_APP_BASEURL}/vendor/subscription/plans`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
      }
    );
    const json = await fetched.json();
    setLoading(false);
    setSubscriptionPlan(json.data);
    // setSubscription(json.data);
  };

  useEffect(() => {
    getAllSubscription();
    getSubscriptionPlan();
  }, []);

  useEffect(() => {
    if (subscription.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = subscription.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(subscription.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, subscription]);

  // Invoke when user click to request another page.

  const onChangeHandler = (e) => {
    setItemsPerPage(e.target.value.split(" ")[1]);
  };
  return (
    <Subscription
      currentItems={currentItems}
      loading={loading}
      pageCount={pageCount}
      pageNum={pageNum}
      onChangeHandler={onChangeHandler}
      subscriptionPlan={subscriptionPlan}
      showModal={showModal}
      setShowModal={setShowModal}
      refetchSubscription={getAllSubscription}
      refReactPaginate={refReactPaginate}
    />
  );
};

export default EventSubscription;
