import React, { Suspense, useState } from "react";
import ReactPaginate from "react-paginate";
import { AiOutlineSearch } from "react-icons/ai";

import Classes from "./eachOrder.module.css";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import DashboardCard from "../../component/dashboardCard/dashboardCard";
import { ReactComponent as Money } from "../../asset/money.svg";
import { ReactComponent as Store } from "../../asset/store.svg";
import { ReactComponent as Box } from "../../asset/box.svg";
import { ReactComponent as Bag } from "../../asset/bag.svg";
import { ReactComponent as Left } from "../../asset/left.svg";
// import {ReactComponent as Date} from '../../asset/date.svg';
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

const EachOrder = ({
  pageCount,
  loading,
  handlePageClick,
  refReactPaginate,
  onChangeHandler,
  orders,
}) => {
  const sumUp = (arr) => {
    const func = (total, num) => total + num;
    const intialValue = 0;
    const total = arr.reduce(func, intialValue);
    return total;
  };

  const [searchInput, setSearchInput] = useState("");

  const productPrice = orders.order.map((item) =>
    Number(item.product_discount_price)
  );
  console.log(productPrice);

  const productQuantites = orders.order.map((order) =>
    Number(order.product_qty)
  );

  const totalProductQty = sumUp(productQuantites);
  const mapMyOrders = orders?.order
    .filter((v) =>
      v.product_title.toLowerCase().includes(searchInput.toLowerCase())
    )
    .map((v, i) => (
      <>
        <div key={i} className={`${Classes.mappedOrders} !hidden md:!grid`}>
          <div className={Classes.myOrderItemCover}>
            <img
              src={v.product_thumbnail}
              className={Classes.myOrderItemImage}
              alt="item"
            />
            <p>
              <small>From {v.product_title} stores</small>
              <strong style={{ display: "block", marginTop: "2px" }}>
                {v.name}
              </strong>
            </p>
          </div>
          <div>
            <span>₦{v.product_discount_price}</span>
          </div>
          <div>
            <span>{v?.product_qty}</span>
          </div>
          <div>
            <span>₦{v.product_discount_price * v?.product_qty}</span>
          </div>
        </div>

        <div className="bg-white p-5 mt-5 md:hidden">
          <div className="w-[80px]">
            <img src={v.product_thumbnail} className="w-full" alt="item" />
          </div>
          <p>
            <small>From {v.product_title} stores</small>
            <strong style={{ display: "block", marginTop: "2px" }}>
              {v.name}
            </strong>
          </p>

          <div className="text-sm space-y-3 mt-5">
            <div>
              <span>Unit price: </span>
              <span>₦{v.product_discount_price}</span>
            </div>
            <div>
              <span>Quantity: </span>
              <span>{v?.product_qty}</span>
            </div>
            <div>
              <span>Subtotal: </span>
              <span>₦{v.product_discount_price * v?.product_qty}</span>
            </div>
          </div>
        </div>
      </>
    ));

  const mappedLoading = Array(4)
    .fill("s")
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Orders - Passward Vendor </title>
        <meta name="og:title" content="Orders - Passward Vendor" />
        <meta name="og:description" content="Orders - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className="h-screen w-full p-4">
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Orders" />
          <div className={Classes.gridDashboardCard}>
            <DashboardCard
              label="Total Order"
              // num={`₦${orders.shipping_total}`}
              num={"₦" + " " + `${orders.shipping_total}`}
              svg={<Money />}
            />
            <DashboardCard
              label="Date"
              num={orders.order_placed_on}
              svg={<Bag />}
            />
            <DashboardCard
              label="Product"
              num={totalProductQty}
              svg={<Box width="16px" />}
            />
            <DashboardCard
              label="Customer"
              num={orders.shipping_name}
              svg={<Store />}
            />
          </div>

          <div className="flex flex-col sm:flex-row justify-between mt-10 gap-5">
            <div className="flex ml-auto sm:ml-0 items-center">
              <strong
                style={{ marginRight: "6px" }}
                className="text-sm text-gray-500"
              >
                Order Status:
              </strong>
              {/* {orders.order[0] ? ( */}
              <button className={Classes.trueVisibilityCover}>
                {orders.order[0]?.delivery_status}
              </button>
              {/* ) : ( */}
              {/* <button
                  style={{backgroundColor: '#ff6251'}}
                  className={Classes.trueVisibilityCover}
                >
                  pending
                </button> */}
              {/* )} */}
            </div>

            <div className={Classes.companyDetails}>
              <strong style={{ marginBottom: "10px" }}>
                Logistics Company Details
              </strong>
              <small style={{ marginBottom: "5px" }}>
                Name:{" "}
                {orders?.logistic_details?.first_name +
                  " " +
                  orders?.logistic_details?.last_name}
              </small>
              <small>Phone: {orders.shipping_phone}</small>
            </div>
          </div>

          <div className={Classes.subNavCover}>
            <div className="flex gap-5 sm:flex-row flex-col justify-between items-center mt-10">
              <h3 className={Classes.eachPageHeader}>
                Order ID: {orders.order_code}
              </h3>
              <div className={Classes.sideEachPageHeader}>
                <form className={Classes.secondForm}>
                  <input
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Search Order"
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
              </div>
            </div>
          </div>

          {/* mobile order */}
          <div className="mt-5 md:hidden">{mapMyOrders}</div>

          {/* main order sectioon where the orders are mapped */}
          <div className={`${Classes.cardCover} hidden md:flex`}>
            <div className={Classes.mappedStoresCover}>
              <div className={Classes.cardListingCover}>
                {!mapMyOrders.length < 1 && (
                  <div className={Classes.cardHeader}>
                    <strong style={{ marginRight: "auto" }}>Item</strong>
                    <strong>Unit Price</strong>
                    <strong>Quantity</strong>
                    <strong>Subtotal</strong>
                  </div>
                )}

                {loading ? (
                  mappedLoading
                ) : mapMyOrders.length > 0 ? (
                  mapMyOrders
                ) : (
                  <div className={Classes.noProducts}>
                    <p>No items to show</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* order total */}
          <div className={Classes.totalCover}>
            <strong>Total:</strong>
            <strong
              style={{ color: "#000033", fontSize: "22px", marginLeft: "15px" }}
            >
              ₦{orders.shipping_total}
            </strong>
          </div>

          {/* pagination section */}
          <div style={{ display: "none", marginBottom: "50px", width: "100%" }}>
            <div className={Classes.showingCover}>
              <select
                onChange={onChangeHandler}
                className={Classes.showingSelectCover}
              >
                <option>Showing 1</option>
                <option defaultValue selected>
                  Showing 10
                </option>
                <option>Showing 25</option>
                <option>Showing 50</option>
                <option>Showing 100</option>
              </select>
            </div>

            <ReactPaginate
              ref={refReactPaginate}
              breakLabel="..."
              nextLabel={<Left style={{ transform: "rotate(180deg)" }} />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<Left />}
              className={Classes.paginationClass}
              renderOnZeroPageCount={null}
              disabledClassName={Classes.disabledClassName}
            />
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default EachOrder;
