import React, { useEffect, useState, useContext, useRef } from "react";
import Classes from "./withdrawalSettings.module.css";
import Input from "../../component/input/input";
import Card from "../../component/card/card";
import Button from "../../component/button/button";
import { States } from "../../data/stateJson";
import axios from "axios";
import { MainContext } from "../../App";
import { GetUserDetails } from "../../helper/getUserHelper";
import { ReactComponent as FB } from "../../asset/facebook.svg";
import { ReactComponent as IG } from "../../asset/instagram.svg";
import { ReactComponent as TW } from "../../asset/twitter.svg";
import { ReactComponent as LI } from "../../asset/linkedin.svg";
import { useLocation } from "react-router-dom";
import Withdrawal from "./withdrawal";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const RightSide = () => {
  const [toggle, setToggler] = useState(false);
  const location = useLocation();
  const [storeImg, setStoreImg] = useState();
  const { getUser } = GetUserDetails();
  const CTX = useContext(MainContext);
  const [state, setState] = useState("");
  const imgRef = useRef();
  const [inputs, setInputs] = useState(
    {
      ...CTX.user,
      social_detail: {
        fb_url: CTX.user.social_detail?.fb,
        linkedin_url: CTX.user.social_detail?.linkedin,
        twitter_url: CTX.user.social_detail?.twitter,
        instagram_url: CTX.user.social_detail?.instagram,
        website: "",
      },
    } || {}
  );
  const [base, setBase] = useState(inputs.store_detail.store_status ?? "");
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;

  const [time, setTime] = useState({
    opening_time: inputs?.store_detail?.opening_time,
    closing_time: inputs?.store_detail?.closing_time,
  });

  const [loading, setLoading] = useState(false);
  const onSubmitDetailsHandler = async (e) => {
    e.preventDefault();
    console.log("imgRef.current => ", imgRef);
    setLoading(true);
    try {
      var formData = new FormData();
      // formData.append('profile_image', imgValue.target.files[Object.keys(imgValue.target.files)]);
      formData.append("first_name", inputs.first_name);
      formData.append("last_name", inputs.last_name);
      formData.append("store_name", inputs?.store_detail?.name);
      formData.append("state", state);
      formData.append("phone_number", inputs?.phone);
      formData.append("description", inputs?.store_detail?.description);
      formData.append("address", inputs?.store_detail?.address);
      formData.append("website", inputs?.social_detail?.website);
      formData.append("fb_url", inputs?.social_detail?.fb_url);
      formData.append("fb", inputs?.social_detail?.fb_url);

      formData.append("linkedin_url", inputs?.social_detail?.linkedin_url);
      formData.append("twitter_url", inputs?.social_detail?.twitter_url);
      formData.append("instagram_url", inputs?.social_detail?.instagram_url);
      const request = await axios({
        url: `${process.env.REACT_APP_BASEURL}/vendor/setting/profile`,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
        data: formData,
      });
      const response1 = await request.data;
      const response2 = await updateStore();

      if (response1.status !== 200 && response2.status !== 200)
        throw new Error("Opps error updating profile");
      setLoading(false);
      toast("Details saved successfully");
      getUser();
    } catch (error) {
      setLoading(false);
      setTimeout(() => {}, 4000);
      toast("Invalid details");
      console.log(error);
    }
  };

  const updateStore = async () => {
    const request = await axios({
      url: `${process.env.REACT_APP_BASEURL}/vendor/setting/store/update`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${_tk}`,
      },
      data: {
        store_name: inputs.store_detail?.name,
        address: inputs.store_detail?.address,
        store_location: inputs.store_detail?.state,
        description: inputs.store_detail?.description,
        store_status: base,
        ...time,
      },
    });

    const response = await request.data;

    return response;
  };

  const onChangeHandlerForStores = (name, e) => {
    const spread = { ...inputs };
    spread.store_detail[name] = e.target.value;
    setInputs(spread);
  };

  const onChangeHandlerForWebsites = (name, e) => {
    const spread = { ...inputs };
    spread.social_detail[name] = e.target.value;
    setInputs(spread);
  };

  useEffect(() => {
    if (location.search.split("=")[1] === "withdrawal") {
      setToggler(true);
    } else {
      setToggler(false);
    }
    // window.location.search.split('=')[0] === '?token'
  }, [location]);

  const imageUploader = async (file) => {
    setStoreImg(file);
    setLoading(true);

    try {
      setLoading(false);
      var formData = new FormData();
      formData.append("store_logo", file);
      // formData.append('profile_image', imgValue.target.files[Object.keys(imgValue.target.files)]);
      const request = await axios({
        url: `${process.env.REACT_APP_BASEURL}/vendor/setting/store/logo/update`,
        data: formData,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
      });

      const { status } = request;
      if (status === 200) {
        toast("Store logo updated Successfully");
      }
      getUser();
    } catch (error) {
      setLoading(false);
      toast("Check your internet connection");
      console.log(error);
    }
  };
  useEffect(() => {
    if (!CTX.user.state) return;
    setState(CTX.user.state);
  }, [inputs, CTX.user]);

  return (
    <div>
      <div className={Classes.pageHeader}>Account / Withdrawal Settings</div>
      <Card style={{ height: "max-content" }}>
        <div className={Classes.paddingCard}>
          <div className={Classes.flexTwo}>
            <Link
              to="/settings?type=account"
              style={{ textDecoration: "none", marginRight: "50px" }}
            >
              <div
                style={{
                  backgroundColor: !toggle ? "#ff0166" : "#f7f7f7",
                  color: !toggle ? "#fff" : "#6c757d",
                }}
                onClick={() => setToggler(false)}
                className={Classes.twoRoutes}
              >
                Account
              </div>
            </Link>
            <Link
              to="/settings?type=withdrawal"
              style={{ textDecoration: "none" }}
            >
              <div
                style={{
                  backgroundColor: toggle ? "#ff0166" : "#f7f7f7",
                  color: toggle ? "#fff" : "#6c757d",
                }}
                onClick={() => setToggler(true)}
                className={Classes.twoRoutes}
              >
                Withdrawal
              </div>
            </Link>
          </div>

          {!toggle && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className={Classes.personalInfoCover}>
                {/* <AiOutlineUser style={{marginRight: '10px', lineHeight: '1'}} /> */}
                <span style={{ lineHeight: "1" }}>PERSONAL INFORMATION</span>
              </div>
              <div className={Classes.gridTwo}>
                <Input
                  type="input"
                  value={inputs?.first_name}
                  onChange={(e) =>
                    setInputs({ ...inputs, first_name: e.target.value })
                  }
                  label="First Name"
                />
                <Input
                  type="input"
                  value={inputs?.last_name}
                  onChange={(e) =>
                    setInputs({ ...inputs, last_name: e.target.value })
                  }
                  label="Last Name"
                />
              </div>
              <div className={Classes.gridTwo}>
                <Input
                  type="input"
                  inputType="email"
                  label="Email Address"
                  value={CTX.user.email}
                  readOnly={true}
                  msg="Registered email address can not be modified."
                />
                <Input
                  value={inputs?.phone}
                  onChange={(e) =>
                    setInputs({ ...inputs, phone: e.target.value })
                  }
                  type="input"
                  label="Phone Number"
                />
              </div>

              <div className={Classes.personalInfoCover}>
                <span style={{ lineHeight: "1" }}>STORE INFORMATION</span>
              </div>

              <div className={Classes.gridTwo}>
                <Input
                  value={inputs?.store_detail?.name}
                  onChange={onChangeHandlerForStores.bind(this, "name")}
                  type="input"
                  inputType="text"
                  label="Store Name"
                />
                <Input
                  type="select"
                  // required
                  selected={state}
                  options={["Choose State"].concat(
                    States.map((v) => {
                      return v.name;
                    })
                  )}
                  label="Store Location"
                  onChange={(e) => setState(e.target.value)}
                />
              </div>

              <div className={Classes.gridTwo}>
                <Input
                  value={inputs?.store_detail?.address}
                  onChange={onChangeHandlerForStores.bind(this, "address")}
                  type="input"
                  inputType="text"
                  label="Address"
                />
                <div>
                  <Input
                    type="file"
                    placeholder={storeImg ? storeImg.name : "Choose file"}
                    inputType="file"
                    label="Store Image"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => imageUploader(e)}
                    // onBlur={(e) => onBlurImageHandler(e)}
                    removeImage={() => setStoreImg(null)}
                    className={
                      "text-[#444445] font-bold text-sm  p-1 relative hover:cursor-pointer bg-[#F0F0F0]"
                    }
                  />
                  <p className="text-xs mt-[-.5rem]">
                    Image size should be 70 by 70
                  </p>
                </div>
              </div>

              <div className={`${Classes.gridTwo} mt-3`}>
                <Input
                  type="time"
                  inputType="time"
                  label="Opening time"
                  value={time?.opening_time}
                  onChange={(e) =>
                    setTime((prev) => ({
                      ...prev,
                      opening_time: e.target.value,
                    }))
                  }
                />
                <Input
                  type="time"
                  inputType="time"
                  label="Closing time"
                  value={time?.closing_time}
                  onChange={(e) =>
                    setTime((prev) => ({
                      ...prev,
                      closing_time: e.target.value,
                    }))
                  }
                />
                {/* {console.log(time?.opening_time)}
                {console.log(time?.closing_time)} */}
              </div>
              <Input
                type="select"
                // required
                selected={base}
                options={["Home Based", "Shop Based", "Shared Space"].map(
                  (v) => {
                    return v;
                  }
                )}
                label="Shop Type"
                onChange={(e) => setBase(e.target.value)}
              />
              <Input
                type="textarea"
                label="Description"
                value={inputs?.store_detail?.description}
                onChange={onChangeHandlerForStores.bind(this, "description")}
              />
              <div className={Classes.personalInfoCover}>
                <span style={{ lineHeight: "1" }}> SOCIAL MEDIA</span>
              </div>

              <div className={Classes.gridTwo}>
                <div className={Classes.amountCover}>
                  <div className={Classes.nairaCover}>
                    <FB />
                  </div>
                  <Input
                    type="url"
                    // required={inputs.social_detail.fb_url.length >= 2 ? false : true}
                    value={
                      inputs?.social_detail?.fb_url === "undefined"
                        ? ""
                        : inputs?.social_detail?.fb_url
                    }
                    onChange={onChangeHandlerForWebsites.bind(this, "fb_url")}
                    label="Facebook"
                    style={{ paddingLeft: "60px" }}
                    marb={true}
                    placeholder="Facebook URL"
                  />
                </div>
                <div className={Classes.amountCover}>
                  <div className={Classes.nairaCover}>
                    <TW />
                  </div>
                  <Input
                    type="url"
                    // required={inputs.social_detail.twitter_url.length >= 2 ? false : true}
                    label="Twitter"
                    value={
                      inputs?.social_detail?.twitter_url === "undefined"
                        ? ""
                        : inputs?.social_detail?.twitter_url
                    }
                    onChange={onChangeHandlerForWebsites.bind(
                      this,
                      "twitter_url"
                    )}
                    style={{ paddingLeft: "60px" }}
                    marb={true}
                    placeholder="Twitter URL"
                  />
                </div>
                <div className={Classes.amountCover}>
                  <div className={Classes.nairaCover}>
                    <IG />
                  </div>
                  <Input
                    type="url"
                    style={{ paddingLeft: "60px" }}
                    marb={true}
                    // required={inputs.social_detail.instagram_url.length >= 2 ? false : true}
                    label="Instagram"
                    value={
                      inputs?.social_detail?.instagram_url === "undefined"
                        ? ""
                        : inputs?.social_detail?.instagram_url
                    }
                    onChange={onChangeHandlerForWebsites.bind(
                      this,
                      "instagram_url"
                    )}
                    placeholder="Instagram URL"
                  />
                </div>

                <div className={Classes.amountCover}>
                  <div className={Classes.nairaCover}>
                    <LI />
                  </div>
                  <Input
                    style={{ paddingLeft: "60px" }}
                    marb={true}
                    type="url"
                    label="Linkedin"
                    placeholder="Linkedin URL"
                    // required={inputs.social_detail.linkedin_url.length >= 2 ? false : true}
                    value={
                      inputs?.social_detail?.linkedin_url === "undefined"
                        ? ""
                        : inputs?.social_detail?.linkedin_url
                    }
                    onChange={onChangeHandlerForWebsites.bind(
                      this,
                      "linkedin_url"
                    )}
                  />
                </div>
              </div>

              <Button
                style={{ marginTop: "30px", height: "38px" }}
                text="Save Changes"
                bgColor="#1abc9c"
                loading={loading}
                onClick={onSubmitDetailsHandler}
              />
            </form>
          )}

          {toggle && <Withdrawal />}
        </div>
      </Card>
    </div>
  );
};

export default RightSide;
