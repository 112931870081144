// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.singleProduct_flexSection__wcE-X {\n    width: 100%;\n    display: flex;\n  }\n  \n.singleProduct_eachPageHeader__0DHBT {\n    /* margin-top: 8px; */\n    color: #7f7f7f;\n  }", "",{"version":3,"sources":["webpack://./src/pages/singleProduct/singleProduct.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,aAAa;EACf;;AAEF;IACI,qBAAqB;IACrB,cAAc;EAChB","sourcesContent":["\n.flexSection {\n    width: 100%;\n    display: flex;\n  }\n  \n.eachPageHeader {\n    /* margin-top: 8px; */\n    color: #7f7f7f;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexSection": "singleProduct_flexSection__wcE-X",
	"eachPageHeader": "singleProduct_eachPageHeader__0DHBT"
};
export default ___CSS_LOADER_EXPORT___;
