import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import AllRoutes from "./routes";
import { GlobalProvider } from "./context/GlobalProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const url = process.env.REACT_APP_BASEURL;
  const [token, setToken] = useState(sessionStorage.getItem("v_tk") || "");
  const [stats, setStats] = useState(
    JSON.parse(sessionStorage.getItem("stats")) || {}
  );
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("user")) || {}
  );

  const openLogin = () => {
    document.body.setAttribute("data-side-login-mode", "open");
  };

  const closeLogin = () => {
    document.body.removeAttribute("data-side-login-mode", "open");
  };

  const removeSideBar = () => {
    document.body.removeAttribute("data-side-navigation-mode");
    document.body.classList.remove("fade-effect");
  };

  const checkLogin = () => {
    if (token.length < 3) {
      openLogin();
    } else {
      return;
    }
  };

  const setStatsHandler = (_payload) => {
    sessionStorage.setItem("stats", JSON.stringify(_payload));
    setStats(_payload);
  };

  const setTokenHandler = (_token) => {
    setToken(_token);
    closeLogin();
    sessionStorage.setItem("v_tk", JSON.stringify(_token));
  };

  const setUserHandler = (_user) => {
    setUser(_user);
    sessionStorage.setItem("user", JSON.stringify(_user));
  };

  const logoutHandler = () => {
    sessionStorage.clear();
    setToken("");
    setUser({});
  };
  // const {data, getSta} = GetUserStatistics();
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <MainContext.Provider
        value={{
          url,
          openLogin,
          closeLogin,
          checkLogin,
          token,
          removeSideBar,
          user,
          setToken: setTokenHandler,
          setUser: setUserHandler,
          logout: logoutHandler,
          setStats: setStatsHandler,
          stats,
        }}
      >
        <GlobalProvider>
          <Toaster
            position="bottom-left"
            toastOptions={{
              style: {
                background: "#ff0066",
                borderLeft: "5px solid #003",
                fontSize: "14px",
                color: "#fff",
              },
            }}
          />

          <AllRoutes />
        </GlobalProvider>
      </MainContext.Provider>
    </QueryClientProvider>
  );
}

export default App;

export const MainContext = React.createContext({
  url: null,
  user: null,
  token: null,
  openLogin: () => {},
  closeLogin: () => {},
  checkLogin: () => {},
  setToken: () => {},
  removeSideBar: () => {},
  logout: () => {},
  setUser: () => {},
  setStats: () => {},
  stats: null,
});

// FIXME: Phone number error not popping up on withdrawal
// BUG:Auto-refresh after withdrawal not working
