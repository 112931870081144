import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
import Loader from "../component/loader";
const SubscribedRoutes = () => {
  const token =
    typeof window !== undefined && JSON.parse(sessionStorage.getItem("v_tk"));
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/vendor/setting/agents`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        const fetchedData = await response.json();
        const { status, message, data } = fetchedData;
        if (status !== 200 && status === 403)
          throw new Error(`Your plan has ${message}`);
        if (status !== 200 && status !== 403) throw new Error(data);
        setLoading(false);
        setExpired(false);
      } catch (e) {
        setExpired(true);
        setLoading(false);
        toast(e.message);
      }
    })();
  }, []);

  if (loading)
    return (
      <div className="text-center mt-5 mx-5">
        <Loader />
      </div>
    );
  return !expired ? <Outlet /> : <Navigate to={"/subscription"} />;
};

export default SubscribedRoutes;
