import React, { useState, useEffect } from "react";
import Classes from "./dashboard.module.css";
import Skeleton from "react-loading-skeleton";
import { Months } from "../../data/monthsJson";
import checkPrice from "../../utils/checkPrice";

const RecentOrder = () => {
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  const mappedOrders = orders.map((v) => (
    <div key={v.order_code} className={Classes.gridBodySection}>
      <span>
        <strong className={Classes.smallStrong}>Order ID:</strong>
        <small>{v.order_code}</small>
      </span>

      <span className={Classes.centerSpan}>
        <strong className={Classes.smallStrong}>Order Quantity</strong>
        <small>{v.product_qty}</small>
      </span>
      <strong className={Classes.smallStrong}></strong>
      <small>{v.created?.split("T")[0]}</small>
      <small>
        {v.order[0].delivery_status ? (
          <div className={Classes.pending}>{v.order[0].delivery_status}</div>
        ) : (
          <div className={Classes.pending}>Pending</div>
        )}
      </small>
      <small>
        ₦
        {checkPrice(v?.product_discount_price, v.product_price) * v.product_qty}
      </small>
    </div>
  ));

  const getRecentOrders = async (months) => {
    setLoading(true);
    try {
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/overview/recent/order?month=${months}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );
      const json = await fetched.json();
      setLoading(false);
      setOrders(json.data);
    } catch (error) {
      console.log("error => ", error);
      // setLoading(false)
    }
  };

  useEffect(() => {
    getRecentOrders(Months[new Date().getMonth()].name);
  }, []);

  const mapLoading = Array(3)
    .fill("")
    .map((v, i) => (
      <div key={i} className={Classes.gridBodySection}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  return (
    <div className={Classes.RecentOrderCover}>
      <div style={{ position: "relative" }} className={Classes.headerSection}>
        <strong style={{ color: "#003" }}>Recent Orders</strong>
        <select
          onChange={(e) => getRecentOrders(e.target.value)}
          className={Classes.selectSectionHere}
          style={{ width: "100px" }}
        >
          {Months.map((v, i) => (
            <option selected={i === new Date().getMonth()} key={i}>
              {v.name}
            </option>
          ))}
        </select>
      </div>
      {!mappedOrders.length < 1 && (
        <div className={Classes.gridHeaderSection}>
          <strong>Order ID</strong>
          <strong>Order Quantity</strong>
          <strong>Date</strong>
          <strong>Delivery Status</strong>
          <strong>Total</strong>
        </div>
      )}
      {/* {mappedOrders} */}
      {loading ? (
        mapLoading
      ) : mappedOrders.length < 1 ? (
        <div className={Classes.noIncome}>You have no orders yet </div>
      ) : (
        mappedOrders
      )}
    </div>
  );
};

export default RecentOrder;
