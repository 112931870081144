import React, { useState, useEffect } from "react";
import Classes from "./dashboard.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Months } from "../../data/monthsJson";

const GraphSection = () => {
  const [orderChart, setOrderChart] = useState([
    {
      week: 1,
      count: 2,
    },
    {
      week: 2,
      count: 0,
    },
    {
      week: 3,
      count: 0,
    },
    {
      week: 4,
      count: 0,
    },
    {
      week: 5,
      count: 2,
    },
  ]);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  // setting up chartJS line details
  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          drawBorder: false,
          padding: 20,
          color: "#003",
        },
        grid: {
          borderDash: [10, 10],
          color: "#969696",
        },
      },
      y: {
        ticks: {
          color: "#003",
          padding: 20,
          borderDash: [10, 10],
        },

        grid: {
          borderDash: [10, 10],
          color: "#969696",
        },
        min: 0,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const labels = orderChart.map((v) => `WK${v.week}`);
  // console.log(orderChart.map((v) => v.count))
  const graphData = {
    labels,
    datasets: [
      {
        label: " ",
        data: orderChart.map((v) => ({ y: v.count, x: v.week })),
        // data: [23, 34, 54, 64, 64, 23, 100, 200, 900, 232, 434, 534],
        pointRadius: 0,
        width: "1px",
        color: "#000033",
        borderColor: "#969696",
      },
    ],
  };
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;
  // function to get revenue graph chart data from BackEnd with year as the params
  const getUserGraph = async (months) => {
    try {
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/overview/order/chart?month=${months}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );
      const json = await fetched.json();
      // console.log("json order chart", json)
      setOrderChart(json.data);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  useEffect(() => {
    getUserGraph(Months[new Date().getMonth()].name);
  }, []);

  return (
    <div className={Classes.firstGraph}>
      <div className={Classes.headerSection}>
        <strong style={{ color: "#003" }} className={Classes.strongHeaderHERE}>
          Orders
        </strong>
        <select
          className={Classes.selectSectionHere}
          style={{ width: "max-content" }}
          onChange={(e) => getUserGraph(e.target.value)}
        >
          {Months.map((v, i) => (
            <option
              selected={v.name === Months[new Date().getMonth()].name}
              key={i}
            >
              {v.name}
            </option>
          ))}
        </select>
      </div>

      {/* the graph is here */}
      <div style={{ height: "23px" }}></div>

      <div className={Classes.padGraphSection}>
        <Line options={options} data={graphData} />
      </div>
    </div>
  );
};

export default GraphSection;
