import React, { useState, useEffect } from "react";
import { Modal } from "../../component/modal/modal";
import toast from "react-hot-toast";
import {
  AiFillDelete,
  AiOutlineArrowLeft,
  AiOutlineCheck,
  AiOutlineSearch,
} from "react-icons/ai";
import ComponentVisibilty from "../../component/component-visibility";
import Spinner from "../../component/loaders/spinner";
const MessageCustomer = ({
  setAllCustomers,
  showModal,
  setShowModal,
  selectedCustomer,
}) => {
  const [loading, setLoading] = useState(false);
  const [allMessage, setAllMessage] = useState([]);
  const [msgSlide, setMsgSlide] = useState(false);
  const [customerDm, setCustomerDm] = useState(null);
  const [msgAll, setMsgAll] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [textCustomers, setTextCustomers] = useState("");
  const [searchMessage, setSearchMessage] = useState("");
  // const [sending,setSending]=useState(false)
  const _tk =
    // eslint-disable-next-line valid-typeof
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;
  const getPrevMessage = async () => {
    setLoading(true);
    const fetched = await fetch(
      `${process.env.REACT_APP_BASEURL}/vendor/customers/message`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
      }
    );
    const json = await fetched.json();
    setLoading(false);
    setAllMessage(json.data);
    console.log(json);
  };

  useEffect(() => {
    getPrevMessage();
    (async () => {
      setLoading(true);
      try {
        const fetched = await fetch(
          `${process.env.REACT_APP_BASEURL}/vendor/customers`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${_tk}`,
            },
          }
        );
        const { status } = await fetched.json();
        if (status !== 200) throw new Error("Opps something went wrong");
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e.message);
      }
    })();
  }, []);

  const sendToAll = async () => {
    setLoading(true);
    try {
      const request = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/customers/message/send`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${_tk}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: textCustomers,
            customers: selectedCustomer,
          }),
        }
      );
      const { data, status, message } = await request.json();
      setLoading(false);
      if (status !== 200)
        throw new Error(message ?? "Opps something went wrong");
      setTextCustomers("");
      setAllCustomers((prev) =>
        prev.map((item) => ({ ...item, selected: false }))
      );
      setShowModal(false);
      setMsgAll(false);
      toast(data[0]);
      getPrevMessage();
    } catch (e) {
      toast(e.message);
    }
  };
  const resendMessage = async (text) => {
    const customer = selectedCustomer.length > 0 ? selectedCustomer : [];
    setLoading(true);
    try {
      const request = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/customers/message/send`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${_tk}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: text,
            customers: customer,
          }),
        }
      );
      const { data, status } = await request.json();
      console.log(data);
      if (status !== 200) throw new Error("Opps something went wrong");
      setLoading(false);
      toast(data[0]);
      getPrevMessage();
    } catch (e) {
      toast(e.message);
    }
  };

  const msgCustomer = (msgDetails) => {
    setCustomerDm(msgDetails);
    setMsgSlide(true);
  };
  const handleFilterCustomers = allMessage.filter((item) =>
    item.message.toLowerCase().includes(searchMessage.toLowerCase())
  );
  const deleteMessage = async (pk) => {
    setLoading(true);
    try {
      const request = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/customers/message/delete/${pk}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Token ${_tk}`,
            "Content-Type": "application/json",
          },
        }
      );
      const { data, status } = await request.json();
      console.log(data);
      if (status !== 200) throw new Error("Opps something went wrong");
      setLoading(false);
      toast("Deleted Successfully");
      getPrevMessage();
    } catch (e) {
      toast(e.message);
    }
  };
  console.log(customerDm);

  return (
    <Modal
      className={
        "w-full relative transition-all overflow-hidden !max-w-[450px] h-[500px]"
      }
      show={showModal}
      close={() => setShowModal(!showModal)}
    >
      {/* Messages */}
      <div
        className={`absolute h-[462px] ${
          msgAll ? "top-[100%]" : "top-[8%]"
        }  flex flex-col transition-all w-full  left-0 ${
          msgSlide ? "left-[-100%]" : "left-0"
        }`}
        onClick={() => setShowSearch(false)}
      >
        <div className="px-2" onClick={(e) => e.stopPropagation()}>
          <input
            placeholder="search for customer"
            value={searchMessage}
            onChange={(e) => setSearchMessage(e.target.value)}
            type="text"
            className={`w-full outline-none px-2 ${
              showSearch ? "border h-auto" : "border-none h-0"
            }`}
          />
        </div>

        <header className="text-center relative font-bold flex justify-center gap-2 text-xl">
          <button
            className="absolute top-[4%] left-[4%]"
            onClick={(e) => {
              e.stopPropagation();
              setShowSearch(true);
            }}
          >
            <AiOutlineSearch />
          </button>
          Messages
          <button
            onClick={() => setMsgAll(true)}
            className="absolute right-[10px] text-xs top-[30%] text-[#ff0066] font-bold"
          >
            Write message
          </button>
        </header>
        <div className="flex-1 flex flex-col overflow-auto ">
          {allMessage.length === 0 && (
            <p className="text-center m-auto font-bold text-xl">No Messages</p>
          )}
          {!searchMessage
            ? allMessage?.map(
                (
                  { message, pk, first_name, last_name, customer_id },
                  index
                ) => (
                  <div key={index}>
                    <button
                      onClick={() =>
                        msgCustomer({
                          message,
                          pk,
                          last_name,
                          first_name,
                          customer_id,
                        })
                      }
                      className="flex gap-3 w-full justify-between p-2 py-3 border-b text-start font-bold text-[#ff0066]"
                    >
                      <div>
                        {message && (
                          <p className="font-bold text-xs text-black flex">
                            {message}
                            <AiOutlineCheck color="green" className=" mx-3" />
                          </p>
                        )}
                      </div>
                      <AiFillDelete
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteMessage(pk);
                        }}
                      />
                    </button>
                  </div>
                )
              )
            : handleFilterCustomers?.map(
                (
                  { message, pk, first_name, last_name, customer_id },
                  index
                ) => (
                  <div key={index}>
                    <button
                      onClick={() =>
                        msgCustomer({
                          message,
                          pk,
                          last_name,
                          first_name,
                          customer_id,
                        })
                      }
                      className="flex gap-3 w-full p-2 py-3 border-b text-start font-bold text-[#ff0066]"
                    >
                      <div>
                        <p className="font-bold text-xs text-black flex">
                          {message}
                          <AiOutlineCheck color="green" className=" mx-3" />
                        </p>
                      </div>
                    </button>
                  </div>
                )
              )}
          {/* {allCustomers.length===0&&<h1 className="text-center mt-5 font-bold">{'No Customers'}</h1>} */}
        </div>
      </div>

      {/* Direct message */}
      <div
        className={`transition-all top-0  ${
          msgSlide ? "left-0" : "left-[150%]"
        } absolute h-full  w-full pt-5`}
      >
        <div className="flex pt-5">
          <button
            className="absolute top-[4%] left-[4%]"
            onClick={() => setMsgSlide(false)}
          >
            <AiOutlineArrowLeft size={25} />
          </button>
          {/* <p className="absolute top-[4%] left-[15%] font-bold">
            {customerDm?.first_name} {customerDm?.last_name}
          </p> */}
        </div>

        <div className="flex-col flex relative h-[460px] ">
          <div className="flex-1 flex flex-col space-y-2 p-2 overflow-auto">
            <p className=" text-white font-medium p-3 rounded-lg bg-[#ff0066]">
              {customerDm?.message}
            </p>
          </div>
          <div className="w-full flex ">
            <button
              onClick={() => resendMessage(customerDm?.message)}
              className="text-white border p-2 border-[#ff0066] bg-[#ff0066] w-full"
              disabled={loading}
            >
              {loading ? (
                <Spinner className={"mx-auto"} color={"secondary"} />
              ) : (
                "Resend"
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Message all */}
      <div
        className={`px-2 pt-10 ${
          msgAll ? "top-[0]" : "top-[100%]"
        } h-full  w-full left-0 absolute `}
      >
        <button onClick={() => setMsgAll(false)} className="absolute top-[4%]">
          <AiOutlineArrowLeft />
        </button>

        <div className="mt-5">
          <ComponentVisibilty appear={selectedCustomer.length === 0}>
            <p className="text-xs mb-5">
              Note: This message will be sent to all your customers. kindly
              select a customer if you wish to send to a particular customer
            </p>
          </ComponentVisibilty>
          <textarea
            className="w-full border-2 outline-none"
            name=""
            id=""
            cols="30"
            rows="10"
            value={textCustomers}
            onChange={(e) => setTextCustomers(e.target.value)}
            maxLength={150}
          ></textarea>
          <div className="flex justify-between">
            <p className="text-xs">
              word count limit:{150 - textCustomers.length}
            </p>
            <button
              className="bg-[#ff0066] text-white p-2 px-4"
              onClick={sendToAll}
            >
              {loading ? (
                <Spinner className={"mx-auto"} color={"secondary"} />
              ) : (
                "Send"
              )}{" "}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MessageCustomer;
