import React from "react";
import Classes from "./lazyLoading.module.css";
import SemiLogo from "../../asset/logo_semi.png";
import RightLogo from "../../asset/star.png";
import Loading from "../loading/loading";

const LazyLoading = () => {
  return (
    <div className={Classes.flexSection}>
      <div className="h-screen w-full p-4">
        <div className={Classes.LoginFormCover}>
          <div className={Classes.relative}>
            <img src={SemiLogo} className={Classes.topRightLogo} alt="img" />
            <img src={RightLogo} className={Classes.rightLogo} alt="img" />
            <img src={RightLogo} className={Classes.leftLogo} alt="img" />
            <img src={RightLogo} className={Classes.topLeftLogo} alt="img" />
            <img src={SemiLogo} className={Classes.semiLogo} alt="img" />
            <Loading
              className={Classes.Loading}
              style={{
                fill: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              size="60px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LazyLoading;
