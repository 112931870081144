import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const useValidateBankMutation = () => {
  const validateBank = async (details) => {
    try {
      const request = await axios.post(
        `${process.env.REACT_APP_BASEURL}/misc/account/resolve`,
        details
      );
      const response = request.data;
      return response;
    } catch (err) {
      return err.response.data;
    }
  };

  const mutation = useMutation({ mutationFn: validateBank });

  return mutation;
};

export default useValidateBankMutation;
