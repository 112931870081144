import React from "react";
import Classes from "./eachSingleProduct.module.css";
import { ReactComponent as Location } from "../../../asset/location.svg";
import { AiOutlinePhone } from "react-icons/ai";
import ImageCarousel from "../../../component/image-carousel";
import ComponentVisibilty from "../../../component/component-visibility";
import joinClasses from "../../../utils/joinClasses";
import checkPrice from "../../../utils/checkPrice";

const SingleProduct = ({ data }) => {
  return (
    <div className={Classes.singleCover}>
      <div className={"flex xl:flex-row flex-col"}>
        <div className="flex-1 relative">
          <ImageCarousel images={data?.product_gallery} />
        </div>
        <div
          className={joinClasses(Classes.imageDescriptionSection, "p-5 w-full")}
          style={{
            boxShadow:
              " 2px 2px 50px rgba(0, 141, 136, 0.08),-2px -2px 10px rgba(0, 141, 136, 0.08)",
          }}
        >
          <div className="mb-3 flex justify-between items-center">
            <div className={` ${Classes.StoreSection}`}>{data.tag}</div>
            <ComponentVisibilty appear={!data.is_returnable}>
              <div
                className={`bg-[#ff0066] p-2 text-white rounded-xl rounded-tr-none text-sm`}
              >
                Non-returnable
              </div>
            </ComponentVisibilty>
          </div>
          <h1 className="font-bold text-3xl">{data.title}</h1>
          <p className="my-3">{data.short_description}</p>
          {data.price && (
            <>
              <h3 className="text-2xl font-bold text-gray-500">
                ₦
                {checkPrice(data?.discount_price, data?.price).toLocaleString()}
              </h3>

              <div className="flex justify-between items-center h-[20px]">
                <ComponentVisibilty appear={data?.discount_price !== 0}>
                  <div>
                    <strong className="line-through text-xs">
                      {data?.price > data?.discount_price &&
                        `₦${data?.price.toLocaleString()}`}
                    </strong>
                    <strong className="text-xs text-[#ff0066] flex-1">
                      <ComponentVisibilty
                        appear={data?.price > data?.discount_price}
                      >
                        {`-${Math.round(
                          ((data?.price - data?.discount_price) * 100) /
                            data?.price
                        )}%`}
                      </ComponentVisibilty>
                    </strong>
                  </div>
                </ComponentVisibilty>
              </div>
            </>
          )}
          <hr className={Classes.hr} />
        </div>
      </div>

      {/* product information section */}
      <div className={Classes.gridTwo}>
        <div className={Classes.innerGridCover}>
          <div className={Classes.gridSectionCenter}>Product Information</div>

          <div
            className={Classes.innerPSection}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />
        </div>
        <div className={Classes.innerGridCover}>
          <div className={Classes.gridSectionCenter}>Vendors’ Information</div>
          <div className={Classes.innerPSection}>
            <strong>{data.store_detail?.name}</strong>
            <div className={Classes.gridInnerVendorInformation}>
              <Location fill="#624F63" width="17px" />
              <small>{data.store_detail?.address}</small>
              <AiOutlinePhone />{" "}
              <small>{data?.store_detail?.vendor_detail?.phone}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
