import React, { useState } from "react";

import Classes from "./login.module.css";
import SemiLogo from "../../asset/logo_semi.png";
import RightLogo from "../../asset/star.png";
import Logo from "../../asset/logo.png";
import Input from "../../component/input/input";
import Button from "../../component/button/button";
import { States } from "../../data/stateJson";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import useSubscriptionInfoQuery from "../../api/subscription/subscription-info";
import { usePaystackPayment } from "react-paystack";
import { useContext } from "react";
import { MainContext } from "../../App";
import useRegisterMutation from "../../api/auth/register";
import { useEffect } from "react";
import useVendorValidateMutation from "../../api/auth/vendor-validate";
import useSubscribeRegisterVendorMutation from "../../api/subscription/subscribe-register-vendor";
import joinClasses from "../../utils/joinClasses";

const Register = () => {
  const CTX = useContext(MainContext);

  const [showPassword, setShowPassword] = useState(false);
  const [showPhoneMSG, setShowPhoneMSG] = useState(false);
  const [showPassMSG, setShowPassMSG] = useState(false);
  const [inputs, setInputs] = useState({});
  const [msg, setMsg] = useState("");
  const [selectedStates, setSelectedStates] = useState("");
  const re = /^(?=.*\d{2})(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const url = window.location.href;
  const referralCode = url.split("=")[1];

  const {
    mutate: vendorValdate,
    data: isValidateData,
    isLoading: loadingValidation,
  } = useVendorValidateMutation(inputs);

  // Registration endpoint
  const {
    mutate: registerVendor,
    data: registerVendorResponse,
    isLoading,
  } = useRegisterMutation();

  const { mutate: subscriptionProcess, isLoading: isSubscriptionProcess } =
    useSubscribeRegisterVendorMutation();

  const { query: subscriptionQuery } = useSubscriptionInfoQuery();
  const { data: subscriptionPlan } = subscriptionQuery;

  const onBlurPhoneHandler = (e) => {
    if (e.target.value.length !== 11) {
      setMsg("Invalid phone number");
      toast("Phone number must be 11 digits");
      return;
    } else {
      setMsg("");
      return;
    }
  };

  const navigate = useNavigate();

  // paystack config starts
  const config = {
    reference: new Date().getTime().toString(),
    email: inputs.email,
    amount: Number(subscriptionPlan?.data?.amount) * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
  };
  const initializePayment = usePaystackPayment(config);

  // run registration
  const handleSubmit = () => {
    if (inputs?.selectedStates === "") {
      console.log(selectedStates);
      return toast("Select a state");
    }
    registerVendor({
      email: inputs.email,
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      state: selectedStates,
      phone_number: inputs.phone_number,
      store_name: inputs.store_name,
      referred_by: referralCode ? referralCode : " ",
      terms: true,
      password: inputs.password,
    });
  };

  useEffect(() => {
    // This will stop the process from running if error was encounter
    if (registerVendorResponse?.status === 400) {
      return;
    }

    // This validation is to make sure that subscription and registration responses are no undefined
    if (
      registerVendorResponse !== undefined &&
      subscriptionPlan !== undefined
    ) {
      if (isSubscriptionProcess) return;
      processSubscription();
    }

    if (registerVendorResponse) {
      handleCompletedRegistrationResponse(subscriptionPlan);
    }
  });

  // on success from the registration, call paystack
  const processSubscription = () => {
    initializePayment(onSuccess, onClose);
  };

  // onSuccess from the paystack, call the endpoint to subscribe vendor
  const onSuccess = ({ reference }) => {
    subscriptionProcess({
      payload: {
        email: inputs.email,
        reference: reference ?? "",
        plan: subscriptionPlan?.data.plan ?? " ",
        plan_name: subscriptionPlan?.data.plan_name ?? " ",
        plan_type: subscriptionPlan?.data.plan_type ?? " ",
      },
      subscriptionPlan,
    });
  };

  const onClose = () => {
    toast.error("Transaction Terminated");
  };

  const handleCompletedRegistrationResponse = () => {
    // At this point, registration is successful
    toast("Registered successfully");
    navigate(`/verification?email=${inputs.email}`);
  };

  useEffect(() => {
    if (!isValidateData) {
      handleSubmit();
    } else {
      if (loadingValidation) return;
    }
  }, [isValidateData, loadingValidation]);
  const [agree, setAgree] = useState(false);
  return (
    <div className={Classes.LoginFormCover}>
      <Helmet>
        <title>Login - Passward Vendor </title>
        <meta name="og:title" content="Login - Passward Vendor" />
        <meta name="og:description" content="Login - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={joinClasses(Classes.relative, "overflow-x-hidden pb-10")}>
        <nav className={Classes.nav}>
          <Link to="/" onClick={() => CTX.closeLogin()}>
            <img src={Logo} className={Classes.logo} alt="Passward Logo" />
          </Link>
        </nav>
        <h1 className={Classes.header}>{"Register"}</h1>
        <form
          autoComplete="off"
          className={Classes.formCover}
          onSubmit={(e) => {
            e.preventDefault();
            vendorValdate();
          }}
        >
          {msg?.length > 0 && (
            <div
              className={Classes.msgAlert}
              style={{
                display: "none",
                textAlign: "center",
                marginBottom: "40px",
              }}
            >
              <p style={{ margin: "0px 0px" }} className={Classes.msgItself}>
                {msg}
              </p>
            </div>
          )}
          <div className={Classes.main_width}>
            <div className={Classes.gridTwo}>
              <Input
                type="input"
                required={true}
                value={inputs?.first_name}
                onChange={(e) =>
                  setInputs({ ...inputs, first_name: e.target.value })
                }
                labelStyle={{ color: "#000033" }}
                label="First Name"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
                placeholder="First Name"
              />

              <Input
                type="input"
                required={true}
                labelStyle={{ color: "#000033" }}
                label="Last Name"
                value={inputs?.last_name}
                onChange={(e) =>
                  setInputs({ ...inputs, last_name: e.target.value })
                }
                placeholder="Last Name"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
              />
            </div>

            <div className={Classes.gridTwo}>
              <Input
                type="input"
                required={true}
                value={inputs?.store_name}
                onChange={(e) =>
                  setInputs({ ...inputs, store_name: e.target.value })
                }
                labelStyle={{ color: "#000033" }}
                label="Store Name"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
                placeholder="Store Name"
              />
              <div>
                <Input
                  type="input"
                  required={true}
                  onBlur={onBlurPhoneHandler}
                  labelStyle={{ color: "#000033" }}
                  label="Phone Number"
                  value={inputs?.phone_number}
                  onChange={(e) => {
                    setInputs({ ...inputs, phone_number: e.target.value });
                    if (e.target.value.length === 11) {
                      return setShowPhoneMSG(false);
                    } else {
                      return setShowPhoneMSG(true);
                    }
                  }}
                  placeholder="Phone Number"
                  style={{
                    backgroundColor: "#fff",
                    height: "50px",
                    border: "1px solid #ff0066",
                  }}
                />
                {showPhoneMSG && (
                  <>
                    {inputs?.phone_number?.length > 1 && (
                      <p className={Classes.must_Contain}>
                        Phone number must be 11 digits
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className={Classes.main_width}>
            <Input
              type="input"
              placeholder="yourmail@domain.com"
              inputType="email"
              value={inputs?.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
              required={true}
              labelStyle={{ color: "#000033" }}
              label="Email"
              style={{
                backgroundColor: "#fff",
                height: "50px",
                border: "1px solid #ff0066",
              }}
            />
          </div>
          <div className={Classes.main_width} style={{ marginTop: "8px" }}>
            <Input
              type="select"
              required
              style={{
                backgroundColor: "#fff",
                height: "50px",
                border: "1px solid #ff0066",
              }}
              labelStyle={{ color: "#000033" }}
              options={["Choose State"].concat(
                States.map((v) => {
                  return v.name;
                })
              )}
              onChange={(e) => setSelectedStates(e.target.value)}
              label="State"
            />
          </div>
          <div className={Classes.main_width} style={{ marginTop: "8px" }}>
            <Input
              type="input"
              required={true}
              value={inputs?.password}
              onChange={(e) => {
                setInputs({ ...inputs, password: e.target.value });
                if (re.test(e.target.value)) {
                  return setShowPassMSG(false);
                } else {
                  return setShowPassMSG(true);
                }
              }}
              labelStyle={{ color: "#000033" }}
              placeholder="* * * * * * *"
              showPassword={() => setShowPassword(!showPassword)}
              inputType={showPassword ? "text" : "password"}
              label="Password"
              style={{
                backgroundColor: "#fff",
                height: "50px",
                border: "1px solid #ff0066",
              }}
            />

            <>
              {showPassMSG && (
                <>
                  {inputs?.password?.length > 1 && (
                    <p className={Classes.must_Contain}>
                      Password must contain an Uppercase, Lowercase, Symbol and
                      at least two numbers{" "}
                    </p>
                  )}
                </>
              )}
            </>
          </div>

          <div className={Classes.main_width} style={{ marginTop: "8px" }}>
            <Input
              type="input"
              value={referralCode}
              labelStyle={{ color: "#000033" }}
              label="Referral Code"
              style={{
                backgroundColor: "#fff",
                height: "50px",
                border: "1px solid #ff0066",
              }}
              placeholder="Referral Code"
              readOnly
            />
          </div>

          <div
            className={Classes.main_width}
            style={{ marginTop: "8px", flexDirection: "row" }}
          >
            <div className="flex gap-x-2 items-center">
              <input
                required
                type="checkbox"
                className={Classes.inputCheckbox}
                checked={agree}
                onClick={() => setAgree(!agree)}
                alt=" "
              />
              <label className={Classes.coloredLabel}>
                Agree to{" "}
                <button
                  type="button"
                  onClick={() =>
                    window.open("https://passward.ng/terms-condition", "_blank")
                  }
                  style={{ textDecoration: "underline" }}
                >
                  Terms and Conditions
                </button>
              </label>
            </div>
          </div>

          <div className={Classes.main_width} style={{ marginTop: "15px" }}>
            <Button
              disabled={!agree}
              text={"Register"}
              style={{ width: "100%", height: "40px" }}
              loading={isLoading}
            />
          </div>
        </form>
        <div className={`mb-10 ${Classes.centeredText}`}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/login")}
          >
            Already a User?
            <span
              style={{
                marginLeft: "5px",
                color: "#FF0066",
                textDecoration: "underline",
              }}
            >
              Login
            </span>
          </span>
        </div>
        <img src={SemiLogo} className={Classes.topRightLogo} alt="img" />
        <img src={RightLogo} className={Classes.rightLogo} alt="img" />
        <img src={RightLogo} className={Classes.leftLogo} alt="img" />
        <img src={RightLogo} className={Classes.topLeftLogo} alt="img" />
        <img src={SemiLogo} className={Classes.semiLogo} alt="img" />
      </div>
    </div>
  );
};

export default Register;

// "react-otp-input": "^2.4.0",
//      "react-plotly.js": "^2.5.1",
// "react-plotlyjs": "^0.4.4",
