import React, { useEffect, useState, useContext } from "react";
import Classes from "./withdrawalSettings.module.css";
import { AiFillSave } from "react-icons/ai";
import Input from "../../component/input/input";
// import Card from '../../component/card/card';
import Button from "../../component/button/button";
import toast from "react-hot-toast";
import { Banks } from "../../data/bankJson";
import axios from "axios";
import { MainContext } from "../../App";
import { GetUserDetails } from "../../helper/getUserHelper";
import useValidateBankMutation from "../../api/banks/validate-bank-account";

const Withdrawal = () => {
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;

  const { getUser } = GetUserDetails();
  const CTX = useContext(MainContext);
  const [withdrawalInput, setWithdrawalInput] = useState({
    ...CTX.user,
    account_num: CTX.user?.account_no,
  });
  const [loadingFetch, setLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState("Abbey Mortgage Bank");
  const [msg, setMsg] = useState("");

  // submiting the bank details with axios fetch
  const onSubmitBankHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const request = await axios({
        url: `${process.env.REACT_APP_BASEURL}/vendor/setting/account`,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
        data: {
          bank: selectedBank,
          account_no: withdrawalInput.account_num,
          account_name: withdrawalInput.account_name,
        },
      });
      console.log("request", request);
      setLoading(false);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      setMsg("Details saved successfully");
      toast("Details saved successfully");
      getUser();
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      setMsg("Invalid details");
      console.log(error);
    }
  };
  const filteredBank = Banks.find((bank) => bank.name === selectedBank);
  const {
    mutate,
    data: bankDetails,
    isIdle,
    isLoading: loadingBankDetails,
  } = useValidateBankMutation();

  useEffect(() => {
    if (isIdle) return;
    setWithdrawalInput((prev) => ({
      ...prev,
      account_name: bankDetails?.data?.account_name ?? "",
    }));
  }, [bankDetails, filteredBank]);

  console.log(bankDetails);
  console.log(filteredBank);
  return (
    <form onSubmit={onSubmitBankHandler}>
      <div className={Classes.personalInfoCover}>
        <span style={{ lineHeight: "1" }}>ACCOUNT DETAILS</span>
      </div>
      <small style={{ textAlign: "center", color: "#6c757d" }}>
        All wallet withdrawals will be sent to the account below.
      </small>
      <Input
        type="select"
        options={Banks.map((v) => {
          return v.name;
        })}
        selected={withdrawalInput?.bank}
        label="Bank"
        onChange={(e) => {
          setSelectedBank(e.target.value);
          mutate({
            account_number: withdrawalInput?.account_num,
            bank_code: Banks.find((bank) => bank.name === e.target.value).code,
          });
        }}
      />
      <div className={Classes.gridTwo}>
        <div>
          <Input
            required
            value={withdrawalInput?.account_num}
            onChange={(e) => {
              setWithdrawalInput({
                ...withdrawalInput,
                account_num: e.target.value,
              });
              mutate({
                account_number: e.target.value,
                bank_code: filteredBank.code,
              });
            }}
            type="input"
            label="Account No"
          />
          {withdrawalInput.account_num?.length !== 10 &&
            !!withdrawalInput.account_num && (
              <p className="text-xs text-[#ff0066] text-right">
                Account number should not be less or more than 10 digit
              </p>
            )}
        </div>
        <Input
          required
          value={withdrawalInput?.account_name}
          onChange={(e) => {
            setWithdrawalInput({
              ...withdrawalInput,
              account_name: e.target.value,
            });
          }}
          type="input"
          inputType="text"
          label="Account Name"
          disabled
        />
      </div>

      <Button
        svg={loadingFetch ? "" : <AiFillSave style={{ marginRight: "5px" }} />}
        text={msg?.length > 1 ? msg : "Save Changes"}
        disabled={withdrawalInput.account_num?.length !== 10}
        style={{ height: "38px" }}
        loading={loadingFetch || loadingBankDetails}
        bgColor={loadingFetch ? "#1abc9c98" : "#1abc9c"}
      />
    </form>
  );
};

export default Withdrawal;
