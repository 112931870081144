import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Input from "../../component/input/input";
import { Modal } from "../../component/modal/modal";
import Classes from "./products.module.css";
import Button from "../../component/button/button";
import toast from "react-hot-toast";
import { GlobalContext } from "../../context/GlobalProvider";
import JoditEditor from "jodit-react";
import joinClasses from "../../utils/joinClasses";
import { ReactComponent as Star } from "../../asset/_.svg";

const ModalSection = ({
  showModal,
  setShowModal,
  isEdit,
  clicked,
  clearClicked,
  reGetProducts,
  category,
}) => {
  // inital states
  const initialInputs = {
    product_name: "",
    product_category: "",
    product_price: "",
    discounted_price: "",
    tag: "Clearance Sales",
    inventory: "",
    product_feature: "",
    description: "",
    is_returnable: null,
  };

  const initialThumbnails = [
    { thumbnail: null, id: 1 },
    { thumbnail: null, id: 2 },
    { thumbnail: null, id: 3 },
    { thumbnail: null, id: 4 },
    { thumbnail: null, id: 5 },
  ];

  // States
  const [msg, setMsg] = useState("");
  const { storeStatistics } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(initialInputs);
  const [thumbnails, setThumbnails] = useState(initialThumbnails);
  // const [content, setContent] = useState("");
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: "Your Message",
    }),
    []
  );

  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;

  const closeModal = () => {
    setShowModal(false);
    setMsg("");
    clearClicked({});
    setInputs(initialInputs);
    setThumbnails(initialThumbnails);
  };

  useEffect(() => {
    setInputs({
      ...inputs,
      product_name: isEdit ? clicked?.title : "",
      product_price: isEdit ? clicked.price : "",
      product_category: isEdit
        ? category.find((item) => item.name === clicked?.category_details?.name)
            ?.pk
        : category[0]?.pk,
      discounted_price: isEdit ? clicked?.discount_price : "",
      tag: isEdit ? clicked?.tag : "Clearance Sales",
      description: isEdit ? clicked.description : "",
      product_feature: isEdit ? clicked.short_description : "",
      inventory: isEdit ? clicked?.inventory : "",
    });
  }, [clicked]);
  console.log(clicked);
  const addProduct = async () => {
    // console.log(category[0].pk)
    const formData = new FormData();

    if (!inputs?.product_name) {
      return toast("Input the product name");
    }
    if (!inputs?.product_category) {
      return toast("Choose a category");
    }
    if (!inputs?.product_price) {
      return toast("Product price is required");
    }

    if (!inputs?.tag) {
      return toast("Choose a tag");
    }
    if (!inputs?.inventory) {
      return toast("Input an inventory amount is required");
    }
    if (!inputs?.product_feature) {
      return toast("Tell customers about product the product features");
    }
    if (!inputs?.description) {
      return toast("A Valid description is required");
    }
    if (inputs?.is_returnable === null) {
      return toast("Choose yes or no id a product is returnable");
    }

    setLoading(true);

    formData.append("title", inputs.product_name);
    formData.append("price", Number(inputs.product_price));
    formData.append("discount_price", Number(inputs.discounted_price));
    formData.append("tag", inputs.tag);
    formData.append("short_description", inputs.product_feature);
    formData.append("description", inputs.description);
    formData.append("inventory", Number(inputs.inventory));
    formData.append("category", inputs.product_category);
    formData.append("is_returnable", inputs.is_returnable);

    if (!thumbnails[0].thumbnail && !isEdit) {
      setLoading(false);
      return toast("Select an image");
    }
    // if (!isEdit) {
    //   formData.append("thumbnail", gallery.coverPhoto, gallery.coverPhoto.name);
    // }

    for (let i = 0; i < thumbnails.length; i++) {
      if (thumbnails[i].thumbnail) {
        formData.append(
          "thumbnail",
          thumbnails[i].thumbnail,
          thumbnails[i].thumbnail.name
        );
      }
    }
    try {
      if (Number(inputs.discounted_price) > Number(inputs.product_price))
        throw new Error("Discounted Price cannot be more than actual price");
      const request = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/product/${
          !isEdit ? "create" : `put/${clicked.pk}`
        }`,
        {
          method: isEdit ? "PUT" : "POST",
          headers: {
            Authorization: `Token ${_tk}`,
          },
          body: formData,
        }
      );
      const { status, message } = await request.json();
      if (status === 400 && message.tag) throw new Error(message.tag[0]);
      if (status !== 200) throw new Error(message);

      toast("Product Uploaded Successfully");
      clearClicked();
      setThumbnails(initialThumbnails);
      setLoading(false);
      setShowModal(false);
      setInputs(initialInputs);
      reGetProducts();
      storeStatistics();
      // setGallery((prev) => ({ ...prev, coverPhoto: null }));
    } catch (e) {
      toast(e.message);

      setLoading(false);
    }
  };

  // console.log(thumbnails);
  const updateSingleImage = async (id, image) => {
    const formData = new FormData();
    formData.append("image", image, image.name);
    formData.append("product", clicked.pk);
    try {
      const request = await fetch(
        `  ${process.env.REACT_APP_BASEURL}vendor/product/gallery/update/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Token ${_tk}`,
          },
          body: formData,
        }
      );
      const { status } = await request.json();
      if (status !== 200)
        throw new Error("Please upload all images and fill in the fields");
      toast("Image updated");
    } catch (e) {
      toast(e.message);
    }
  };
  // const reader=new window.FileReader()
  //  console.log(reader.readAsDataURL('https://res.cloudinary.com/dvecpzqqq/image/upload/v1/passward/vendor/store/product/gallery/2_rehlij'))

  return (
    <Modal top="50%" show={showModal} className="w-[95%]" close={closeModal}>
      <div>
        <h3 className={joinClasses(Classes.confirmRemoval, "mb-5")}>
          {isEdit ? "Edit Product" : "Add Product"}
        </h3>

        <div className={Classes.scrollDeep}>
          <form onSubmit={(e) => e.preventDefault()}>
            {msg.length > 0 && (
              <div
                className={Classes.msgAlert}
                style={{ textAlign: "center", marginBottom: "40px" }}
              >
                <p style={{ margin: "0px 0px" }} className={Classes.msgItself}>
                  {msg}
                </p>
              </div>
            )}
            <div
              onSubmit={(e) => e.preventDefault()}
              className={Classes.gridTwo}
            >
              <div>
                <Input
                  required
                  label="Product Name"
                  type="input"
                  placeholder="Product Name"
                  value={inputs?.product_name}
                  onChange={(e) =>
                    setInputs({ ...inputs, product_name: e.target.value })
                  }
                  maxLength={250}
                />
                {inputs.product_name?.length >= 250 && (
                  <p className="text-xs text-red-400 -mt-3">
                    maximum character exceeded
                  </p>
                )}
              </div>

              <Input
                required
                selected={
                  category.filter(
                    (v) => v.name === clicked?.category_details?.name
                  )[0]?.name
                }
                label="Product Category"
                // required={true}
                type="select"
                options={category.map((v) => {
                  return v.name;
                })}
                value={inputs.product_category}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    product_category: category.find(
                      (item) => item.name === e.target.value
                    ).pk,
                  })
                }
              />
              <Input
                required={true}
                label="Product Price"
                type="number"
                placeholder="Product Price"
                value={inputs?.product_price}
                inputType="number"
                onChange={(e) =>
                  setInputs({ ...inputs, product_price: e.target.value })
                }
              />
              <Input
                label="Discounted Price"
                type="input"
                inputType="number"
                placeholder="Discounted Price"
                value={inputs.discounted_price}
                onChange={(e) =>
                  setInputs({ ...inputs, discounted_price: e.target.value })
                }
              />

              <Input
                required={true}
                label="Tag"
                type="select"
                options={["Clearance Sales", "Deal"]}
                value={inputs.tag}
                onChange={(e) => setInputs({ ...inputs, tag: e.target.value })}
              />

              <Input
                required={true}
                label="Inventory"
                type="number"
                placeholder="Inventory"
                inputType="number"
                value={inputs?.inventory}
                onChange={(e) =>
                  setInputs({ ...inputs, inventory: e.target.value })
                }
              />
            </div>
            <div className={`${Classes.gridOne}`}>
              <div className="flex gap-5 flex-wrap">
                {clicked.product_gallery?.map(({ image, pk }, index) =>
                  index === 0 ? (
                    <div key={pk}>
                      <UpdateGallery
                        onChange={(image) => updateSingleImage(pk, image)}
                        image={image}
                      />
                      <p className="text-[.6rem]">cover image</p>
                    </div>
                  ) : (
                    <UpdateGallery
                      key={pk}
                      image={image}
                      onChange={(image) => updateSingleImage(pk, image)}
                    />
                  )
                )}
                <Input
                  type="file"
                  // required
                  onChange={(images) =>
                    setThumbnails(
                      Array.from(images).map((item) => ({ thumbnail: item }))
                    )
                  }
                  label="Select Images"
                  className={
                    "text-[#444445] font-bold text-sm  p-1 relative hover:cursor-pointer bg-[#F0F0F0]"
                  }
                  placeholder={
                    thumbnails[thumbnails?.length - 1].thumbnail
                      ? `${
                          thumbnails?.filter(
                            (item) => item.thumbnail && item.thumbnail
                          ).length
                        } Files Selected`
                      : "Select an image"
                  }
                  multiple={true}
                  accept="image/*"
                />
              </div>

              <div className="text-[#6c757d]">
                <label
                  htmlFor="returnable"
                  className=" font-bold text-[13px] flex gap-x-1"
                >
                  Is this product returnable <Star />
                </label>
                <div className="flex gap-x-5 text-sm mt-2">
                  <div className="flex gap-x-4">
                    <input
                      type="radio"
                      value={true}
                      name="returnable"
                      onChange={(e) =>
                        setInputs((prev) => ({
                          ...prev,
                          is_returnable: e.target.value.includes(true),
                        }))
                      }
                      required
                    />
                    <p>Yes</p>
                  </div>
                  <div className="flex gap-x-4">
                    <input
                      type="radio"
                      value={false}
                      name="returnable"
                      onChange={(e) =>
                        setInputs((prev) => ({
                          ...prev,
                          is_returnable: e.target.value.includes(true),
                        }))
                      }
                      required
                    />
                    <p>No</p>
                  </div>
                </div>
              </div>

              <Input
                required={true}
                label="Product Feature"
                type="textarea"
                txtPlaceholder="Product feature"
                value={inputs?.product_feature}
                onChange={(e) =>
                  setInputs({ ...inputs, product_feature: e.target.value })
                }
              />
              <label className={`flex font-bold text-[13px] text-[#6c757d]`}>
                Product Description
                <span style={{ marginLeft: "4px" }}>
                  <Star />
                </span>
              </label>
              <div className=" relative h-[200px] overflow-hidden">
                <JoditEditor
                  className="absolute !w-full !h-full"
                  ref={editor}
                  value={inputs.description}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  height={800}
                  onBlur={(newContent) =>
                    setInputs((prev) => ({ ...prev, description: newContent }))
                  } // preferred to use only this option to update the content for performance reasons
                />
              </div>
            </div>
            <div className={joinClasses(Classes.flexRight, "mt-5")}>
              <Button
                text={isEdit ? "Edit Product" : "Add Product"}
                loading={loading}
                // showLoading={false}
                style={{ height: "38px" }}
                onClick={addProduct}
              />
              <Button
                text="Cancel"
                onClick={closeModal}
                style={{
                  marginLeft: "20px",
                  backgroundColor: "#fff",
                  color: "#ff0066",
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export default ModalSection;

const UpdateGallery = ({ image, onChange }) => {
  const imageRef = useRef(null);
  return (
    <button
      onClick={() => imageRef.current.click()}
      className="w-[50px] relative overflow-hidden h-[40px]"
    >
      <img className="w-full h-full" src={image} alt="product" />
      <div className="bg-black h-full w-full opacity-[.6] top-0 left-0 absolute">
        <span className="absolute top-[10px] text-white left-[18px]">+</span>
        <input
          ref={imageRef}
          type="file"
          className={`hidden`}
          onChange={(e) => onChange(e.target.files[0])}
        />
      </div>
    </button>
  );
};
