// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboardCard_DashboardCardCover__dQLU1 {\n  width: 100%;\n  height: 80px;\n  display: flex;\n  padding: 0px 1.2em;\n  justify-content: space-between;\n  align-items: center;\n  box-sizing: border-box;\n  background-color: #e9eaf9;\n}\n\n.dashboardCard_svgCover__iPydY {\n  background-color: #fff;\n  width: 40px;\n  height: 40px;\n  border-radius: 9px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.dashboardCard_textCoverSection__8ybj6 {\n  color: #000033;\n  text-align: right;\n}\n", "",{"version":3,"sources":["webpack://./src/component/dashboardCard/dashboardCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B,mBAAmB;EACnB,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".DashboardCardCover {\n  width: 100%;\n  height: 80px;\n  display: flex;\n  padding: 0px 1.2em;\n  justify-content: space-between;\n  align-items: center;\n  box-sizing: border-box;\n  background-color: #e9eaf9;\n}\n\n.svgCover {\n  background-color: #fff;\n  width: 40px;\n  height: 40px;\n  border-radius: 9px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.textCoverSection {\n  color: #000033;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DashboardCardCover": "dashboardCard_DashboardCardCover__dQLU1",
	"svgCover": "dashboardCard_svgCover__iPydY",
	"textCoverSection": "dashboardCard_textCoverSection__8ybj6"
};
export default ___CSS_LOADER_EXPORT___;
