import React, { useEffect, useState } from "react";
import { PaystackConsumer } from "react-paystack";
import Logo from "../../asset/logo.png";
import { Link } from "react-router-dom";
import Classes from "./plans.module.css";
import Button from "../../component/button/button";
import { ReactComponent as Arrow } from "../../asset/down.svg";
import { ReactComponent as Check } from "../../asset/check.svg";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { axiosPrivate } from "../../config/axios";

const Plans = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [toggleBasic, setToggleBasic] = useState(false);
  const [toggleProfessional, setToggleProfessional] = useState(false);
  const [togglePremium, setTogglePremium] = useState(false);

  // config files for paystack section
  const config = {
    reference: new Date().getTime().toString(),
    email: JSON.parse(localStorage.getItem("registrationInputs"))?.email,
    amount: +`${toggleProfessional ? "₦5000" : "₦50 000"}00`,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
    plan: toggleProfessional ? "PLN_bbwp917vl5vfpj1" : "PLN_yfj1kh0hy95hd57",
  };

  // when payment for paystack is done => then this handler
  const handleSuccess = async (reference) => {
    saveUserDetails(reference.reference);
  };

  const handleClose = () => {
    toast("Transaction unsuccessful");
    console.log("closed");
  };
  const componentProps = {
    ...config,
    text: "Paystack Button Implementation",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  // this function is to save user details in the backend with the reference as th payload
  const saveUserDetails = async (reference) => {
    const inputs = JSON.parse(localStorage.getItem("registrationInputs"));
    setLoading(true);
    try {
      const url = await axiosPrivate({
        url: `vendor/register`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          reference: reference,
          email: inputs.email,
          first_name: inputs.first_name,
          phone_number: inputs.phone_number,
          store_name: inputs.store_name,
          last_name: inputs.last_name,
          state: localStorage.getItem("registrationState"),
          terms: true,
          password: inputs.password,
        },
      });

      setLoading(false);
      console.log("register url => ", url);
      if (url.data.status === 400) {
        toast(url.data.data);
      } else {
        console.log("register url => ", url);

        navigate("/verification");
        toast("Confirm your email address and login");
      }

      return;
    } catch (error) {
      console.log("register error => ", error.response);
      setLoading(false);

      if (error?.response?.data?.data.email) {
        return toast(error?.response?.data?.data.email[0]);
      } else {
        navigate("/login");
        toast(error?.response?.data?.data[0]);
        return;
      }
    }
  };

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("registrationInputs"))) {
      return navigate("/login");
    } else {
      return;
    }
  }, []);

  return (
    <div className={Classes.planCoverSection}>
      <Helmet>
        <title>Select Plans - Passward Vendor </title>
        <meta name="og:title" content="Select Plans - Passward Vendor" />
        <meta name="og:description" content="Select Plans - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>

      <nav className={Classes.nav}>
        <Link to="/">
          <img src={Logo} className={Classes.logo} alt="Passward Logo" />
        </Link>
        <div onClick={() => navigate("/login")} className={Classes.backCover}>
          <FiChevronLeft />
          <span>Back</span>
        </div>
      </nav>

      <h1 className={Classes.Pricing_Plans}>Best Pricing Plans just for you</h1>
      {/* <div className={Classes.mappedPlansCover}>{mappedPlans}</div> */}
      {/* basic */}
      <div className={Classes.mappedPlansCover}>
        <div className={Classes.eachPlanSection}>
          <div className={Classes.headerSection}>
            <div>Basic</div>
          </div>
          <div className={Classes.moneySection}>
            <div className={Classes.mainMoneySection}>₦0.00</div>
            <div
              onClick={() => setToggleBasic(!toggleBasic)}
              className={Classes.plan_typeCover}
            >
              <small className={Classes.plan_type}>
                {toggleBasic ? "Mth" : "Yr"}
              </small>
              <Arrow className={Classes.ArrowSVG} fill="#7F7F7F" />
            </div>
          </div>
          <div className={Classes.smallSection}>One User/One Shop</div>
          <div style={{ margin: "10px auto 0px auto", width: "max-content" }}>
            <Button
              loading={loading}
              style={{ height: "40px" }}
              onClick={() => saveUserDetails("")}
              text="Subscribe"
            />
          </div>

          <div className={Classes.hrLine}>
            <Check className={Classes.CheckSVG} />
            <small>10 Products Upload</small>
            <Check className={Classes.CheckSVG} />
            <small>3rd Party Logistics Support</small>
            <Check className={Classes.CheckSVG} />
            <small>Zero Commission on Sales</small>
          </div>
        </div>

        {/* Professional */}
        <div
          style={{ backgroundColor: "#e9eaf9" }}
          className={Classes.eachPlanSection}
        >
          <div className={Classes.headerSection}>
            <div>Professional</div>
          </div>
          <div className={Classes.moneySection}>
            <div className={Classes.mainMoneySection}>
              {toggleProfessional ? "₦5000" : "₦50 000"}
            </div>
            <div
              onClick={() => setToggleProfessional(!toggleProfessional)}
              className={Classes.plan_typeCover}
            >
              <small className={Classes.plan_type}>
                {toggleProfessional ? "Mth" : "Yr"}
              </small>
              <Arrow className={Classes.ArrowSVG} fill="#7F7F7F" />
            </div>
          </div>
          <div className={Classes.smallSection}>One User/One Shop</div>
          <div style={{ margin: "10px auto 0px auto", width: "max-content" }}>
            <PaystackConsumer {...componentProps}>
              {({ initializePayment }) => (
                <Button
                  style={{ height: "40px" }}
                  loading={loading}
                  onClick={() => initializePayment(handleSuccess, handleClose)}
                  text="Subscribe"
                />
              )}
            </PaystackConsumer>
          </div>

          <div className={Classes.hrLine}>
            <Check className={Classes.CheckSVG} />
            <small>200 Products Upload</small>
            <Check className={Classes.CheckSVG} />
            <small>3rd Party Logistics Support</small>
            <Check className={Classes.CheckSVG} />
            <small>Zero Commission on Sales</small>
            <Check className={Classes.CheckSVG} />
            <small>Passward Agent Support</small>
            <Check className={Classes.CheckSVG} />
            <small>Access to Business Trainings</small>
            <Check className={Classes.CheckSVG} />
            <small>Social Media Ads and Promotions</small>
          </div>
        </div>

        {/* Premium */}
        <div className={Classes.eachPlanSection}>
          <div className={Classes.headerSection}>
            <div>Premium</div>
          </div>
          <div className={Classes.moneySection}>
            <div className={Classes.mainMoneySection}>
              {togglePremium ? "₦15 000" : "₦150 000"}
            </div>
            <div
              onClick={() => setTogglePremium(!togglePremium)}
              className={Classes.plan_typeCover}
            >
              <small className={Classes.plan_type}>
                {togglePremium ? "Mth" : "Yr"}
              </small>
              <Arrow className={Classes.ArrowSVG} fill="#7F7F7F" />
            </div>
          </div>
          <div className={Classes.smallSection}>One User/One Shop</div>
          <div style={{ margin: "10px auto 0px auto", width: "max-content" }}>
            <Button text="Coming Soon" style={{ marginBottom: "30px" }} />
          </div>

          {/* <div className={Classes.hrLine}>
            <Check className={Classes.CheckSVG} />
            <small>200 Products Upload</small>
            <Check className={Classes.CheckSVG} />
            <small>3rd Party Logistics Support</small>
            <Check className={Classes.CheckSVG} />
            <small>Zero Commission on Sales</small>
            <Check className={Classes.CheckSVG} />
            <small>Passward Agent Support</small>
            <Check className={Classes.CheckSVG} />
            <small>Access to Business Trainings</small>
            <Check className={Classes.CheckSVG} />
            <small>Social Media Ads and Promotions</small>
            <Check className={Classes.CheckSVG} />
            <small>Niche Specific & Location Based Ads</small>
            <Check className={Classes.CheckSVG} />
            <small>Home Page Feature and Recommended Products</small>
            <Check className={Classes.CheckSVG} />
            <small>Feature in Blog Content</small>
            
          </div> */}
        </div>
      </div>
      <div style={{ height: "90px" }}></div>
    </div>
  );
};

export default Plans;
