import axios from "../../config/axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import useRemoveSubscriptionInfoMutation from "./remove-subcription-info";

const useSubscribeRegisterVendorMutation = () => {
  const { mutate: removeSubscription } = useRemoveSubscriptionInfoMutation();
  const subscribe = async (context) => {
    const { payload, subscriptionPlan } = context;

    try {
      const request = await axios.post(`vendor/subscribe`, payload);
      const { data } = request;

      if (subscriptionPlan !== undefined) {
        removeSubscription(subscriptionPlan.data.user_ip);
      }
      return data.data;
    } catch (error) {
      toast(error.response.data.message ?? "Something went wrong");
      return error.response.data;
    }
  };

  return useMutation({
    mutationFn: subscribe,
  });
};

export default useSubscribeRegisterVendorMutation;
