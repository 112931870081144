import { useState, useContext } from "react";
import { MainContext } from "../App";
import axios from "axios";
import { LogoutUserDetails } from "./logoutUser";
// import Loading from '../components/loading/loading';

export const GetUserDetails = () => {
  const CTX = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const { logout } = LogoutUserDetails();
  const [data, setData] = useState({});
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;
  const getUser = async () => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASEURL}/vendor/auth`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );

      setLoading(false);
      if (request.data.detail === "access_token expired") {
        logout();
        return;
      } else {
        CTX.setUser(request.data?.data);
        setData(request.data?.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      // setMsg('Invalid login details');
      console.log(error);
    }
  };

  return { loading, getUser, data: data };
};
