import axios from "../../config/axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
const useRemoveSubscriptionInfoMutation = () => {
  const removesubscriptionInfo = async (userIp) => {
    try {
      const request = await axios.delete(`vendor/delete-info/${userIp}`);
      const { data } = request;
      toast("Info cleared Successfully");
      window.location.reload();
      return data;
    } catch (error) {
      console.log("Something went wrong");
    }
  };

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: removesubscriptionInfo,
    onSuccess: () => {
      queryClient.invalidateQueries(["subcription-info"]);
    },
  });
  return mutation;
};

export default useRemoveSubscriptionInfoMutation;
