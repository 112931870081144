import React, { Suspense } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";

import Classes from "./orders.module.css";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";
import { ReactComponent as Left } from "../../asset/left.svg";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

const Orders = ({
  refReactPaginate,
  handlePageClick,
  pageCount,
  loading,
  currentItems,
  searchInput,
  onChangeHandler,
  setSearchInput,
}) => {
  const mappedOrders = currentItems
    .filter((v) =>
      v.order_code.toLowerCase().includes(searchInput.toLowerCase())
    )
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}> Order ID: </strong>
          {v.order_code}
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Products: </strong>
          {v.product_title}
        </div>
        <div className={Classes.myOrderItemCover}>{v.order_placed_on}</div>
        {/* <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Payment Status: </strong>
        {v.delivery_status.toLowerCase() == "pending" ? (
          <button
            style={{backgroundColor: '#ff6251'}}
            className={Classes.trueVisibilityCover}
          >
            pending
          </button>
          ) : (
          <button className={Classes.trueVisibilityCover}>paid</button>
        )}
      </div> */}
        <div className={Classes.myOrderItemCover}>
          ₦{v?.product_discount_price * v?.product_qty}
        </div>
        {/* <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Payment Method: </strong>
        {v.payment_method}
      </div> */}

        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Order Status: </strong>
          {/* {v.order_status ? (
          <button className={Classes.trueVisibilityCover}>paid</button>
        ) : ( */}
          <button
            style={{ backgroundColor: "#ff6251" }}
            className={Classes.trueVisibilityCover}
          >
            {v.delivery_status}
          </button>
          {/* )} */}
        </div>
        <div className={Classes.myOrderItemCover}>
          <Link to={`/my-orders/${v.order}`}>
            <button className={Classes.viewBTN}>View</button>
          </Link>
        </div>
      </div>
    ));

  const mappedLoading = Array(6)
    .fill("s")
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Orders - Passward Vendor </title>
        <meta name="og:title" content="Orders - Passward Vendor" />
        <meta name="og:description" content="Orders - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>

      <div className="h-screen w-full p-4">
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Orders" />
          <div className={Classes.subNavCover}>
            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>Orders</h3>
              <div
                className={Classes.sideEachPageHeader}
                style={{ width: "max-content" }}
              >
                <form className={Classes.secondForm}>
                  <input
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Search Order"
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
                {/* <Button
                  onClick={() => {setShowModal(true); setIsEdit(false)}}
                  text="Add New Product"
                  style={{height: 'max-content', marginTop: '0px'}}
                /> */}
              </div>
            </div>
          </div>

          {/* main orderSection */}

          <div className={Classes.cardCover}>
            <div className={Classes.mappedStoresCover}>
              <div className={Classes.cardListingCover}>
                {mappedOrders.length > 0 && (
                  <div className={Classes.cardHeader}>
                    <strong style={{ marginRight: "auto" }}>Order ID</strong>
                    <strong style={{ marginRight: "auto" }}>Products</strong>
                    <strong style={{ marginRight: "auto" }}>Date</strong>
                    {/* <strong>Payment Status</strong> */}
                    <strong>Unit Price</strong>
                    {/* <strong>Payment Method</strong> */}
                    <strong>Order Status</strong>
                    <strong>Action</strong>
                  </div>
                )}
                {/* {loading ? mappedLoading : mappedOrders} */}
                {loading ? (
                  mappedLoading
                ) : mappedOrders.length > 0 ? (
                  mappedOrders
                ) : (
                  <div className={Classes.noProducts}>
                    <p>You do not have any orders currently</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!mappedOrders.length < 1 && (
            <div
              style={{ display: "flex", marginBottom: "30px", width: "100%" }}
            >
              <div className={Classes.showingCover}>
                <select
                  onChange={onChangeHandler}
                  className={Classes.showingSelectCover}
                >
                  <option>Showing 1</option>
                  <option defaultValue selected>
                    Showing 10
                  </option>
                  <option>Showing 25</option>
                  <option>Showing 50</option>
                  <option>Showing 100</option>
                </select>
              </div>

              <ReactPaginate
                ref={refReactPaginate}
                breakLabel="..."
                nextLabel={<Left style={{ transform: "rotate(180deg)" }} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<Left />}
                className={Classes.paginationClass}
                renderOnZeroPageCount={null}
                disabledClassName={Classes.disabledClassName}
              />
            </div>
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default Orders;
