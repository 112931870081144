import React, { useContext, useEffect, useState } from "react";
import { PaystackConsumer } from "react-paystack";
import toast from "react-hot-toast";
import Input from "../../component/input/input";
import { Modal } from "../../component/modal/modal";
import Classes from "./subscription.module.css";
import Button from "../../component/button/button";
import { MainContext } from "../../App";
import axios from "axios";
import { GlobalContext } from "../../context/GlobalProvider";

const ModalSection = ({
  refetchSubscription,
  showModal,
  setShowModal,
  subscriptionPlan,
  currentItems,
}) => {
  const CTX = useContext(MainContext);
  const [confirm, setConfirm] = useState(false);
  const [planType, setPlanType] = useState("monthly");
  const [planName, setPlanName] = useState("professional");
  const [price, setPrice] = useState("");
  const { storeUserDetails } = useContext(GlobalContext);

  const formOnSubmitHandler = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setConfirm(true);
    }, 1000);
  };
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;

  const getPrice = (type, e) => {
    const value = e.target.value;
    if (type) {
      setPlanName(value);

      const getNameFilter = subscriptionPlan.filter(
        (v) =>
          v.plan_name.toLowerCase() === value.toLowerCase() &&
          v.plan_type.toLowerCase() === planType.toLowerCase()
      )[0];
      setPrice(getNameFilter);
      return;
    } else {
      const getNameFilter = subscriptionPlan.filter(
        (v) =>
          v.plan_name.toLowerCase() === planName.toLowerCase() &&
          v.plan_type.toLowerCase() === value.toLowerCase()
      )[0];
      setPrice(getNameFilter);
      setPlanType(value);
      return;
    }
  };

  useEffect(() => {
    if (subscriptionPlan.length > 0) {
      return setPrice(
        subscriptionPlan.filter(
          (v) =>
            v.plan_name.toLowerCase() === "professional" &&
            v.plan_type.toLowerCase() === "monthly"
        )[0]
      );
    }
  }, [showModal]);

  // paystack content
  // when payment for paystack is done => then this handler
  // config files for paystack section
  const config = {
    reference: new Date().getTime().toString(),
    email: CTX.user.email,
    amount: +`${price.plan_price}00`,
    publicKey: "pk_test_862f8eacb92c99b642b7fe34fdc5edc5b1d992ea",
  };

  const handleSuccess = async (reference) => {
    await saveUserDetails(reference.reference);
  };

  // after a successful transaction, this function is called for the backend
  const saveUserDetails = async (reference) => {
    try {
      console.log(_tk, "token");
      await axios({
        url: `${process.env.REACT_APP_BASEURL}/vendor/subscriptions/subscribe`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
        data: {
          reference,
          plan: price.plan,
          plan_name: price.plan_name,
          plan_type: price.plan_type,
        },
      });

      refetchSubscription();

      setShowModal(false);
      storeUserDetails();
      toast("subscription successful");
    } catch (error) {
      toast("Subscription was not successful, try again");
    }
  };
  const handleClose = () => {
    toast("Transaction unsuccessful");
  };
  const componentProps = {
    ...config,
    text: "Paystack Button Implementation",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  // console.log(subscriptionPlan[subscriptionPlan.length-1]?.plan_name);
  return (
    <Modal
      top="50%"
      show={showModal}
      showClose={confirm && false}
      close={() => setShowModal(!showModal)}
      className="w-full max-w-md"
    >
      <h3 className={Classes.confirmRemoval}>Change Subscription Plan</h3>

      <form className={Classes.cover} onSubmit={formOnSubmitHandler}>
        <Input
          label="Plan"
          // style={{paddingLeft: '60px'}}
          required
          type="select"
          onChange={getPrice.bind(this, true)}
          options={[...new Set(subscriptionPlan.map((v) => v.plan_name))]}
        />
        <Input
          label="Plan Type"
          // style={{paddingLeft: '60px'}}
          required
          type="select"
          onChange={getPrice.bind(this, false)}
          options={[...new Set(subscriptionPlan.map((v) => v.plan_type))]}
        />

        {planName === "Premium" ? (
          <div className={Classes.cover}>
            <div className={Classes.updateDetailsCover}>
              <div style={{ textAlign: "center" }}>
                Premium Plan not available for now
              </div>
            </div>
          </div>
        ) : currentItems[currentItems.length - 1]?.plan_name ? (
          <div className={Classes.cover}>
            <div className={Classes.updateDetailsCover}>
              <div style={{ textAlign: "center" }}>
                You are already on {planName} Plan
              </div>
            </div>
          </div>
        ) : (
          <div className={Classes.cover}>
            <div className={Classes.updateDetailsCover}>
              <div style={{ textAlign: "center" }}>
                ₦{price.plan_price} will be charge from your account.
              </div>
            </div>
          </div>
        )}

        <div className={Classes.flexRight}>
          <PaystackConsumer {...componentProps}>
            {({ initializePayment }) => (
              <Button
                text="Upgrade"
                style={{ width: "130px" }}
                disabled={
                  (currentItems[currentItems.length - 1]?.plan_name ===
                    planName ||
                    planName === "Premium") &&
                  true
                }
                onClick={() =>
                  planName === "Premium"
                    ? toast("Premium plan not available now")
                    : initializePayment(handleSuccess, handleClose)
                }
              />
            )}
          </PaystackConsumer>
          <Button
            text="Cancel"
            onClick={() => setShowModal(false)}
            style={{
              width: "130px",
              marginLeft: "20px",
              backgroundColor: "#fff",
              color: "#ff0066",
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

export default ModalSection;
