export const Months = [
  {
    name: "January",
  },
  {
    name: "February",
  },
  {
    name: "March",
  },
  {
    name: "April",
  },
  {
    name: "May",
  },
  {
    name: "June",
  },
  {
    name: "July",
  },
  {
    name: "August",
  },
  {
    name: "September",
  },
  {
    name: "October",
  },
  {
    name: "November",
  },
  {
    name: "December",
  },
];
