import { useState, useContext } from "react";
import { MainContext } from "../App";
import axios from "axios";
// import Loading from '../components/loading/loading';

export const GetUserStatistics = () => {
  const CTX = useContext(MainContext);
  const [loadingStatistics, setLoading] = useState(false);
  const [data, setData] = useState({});
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;
  const getSta = async () => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASEURL}/vendor/overview`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );
      // setLoading(false);

      setData(request.data.data);
      CTX.setStats(request.data.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return { loadingStatistics, data, getSta };
};

// HD2020/07289/1/CS
