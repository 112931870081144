import React, { Suspense } from "react";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import Classes from "./products.module.css";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";

import Button from "../../component/button/button";
import { AiOutlineSearch } from "react-icons/ai";
import { ReactComponent as Trash } from "../../asset/trash.svg";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as Eye } from "../../asset/openeye.svg";
import { ReactComponent as Edit } from "../../asset/edit.svg";
import { Modal } from "../../component/modal/modal";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import { ReactComponent as Left } from "../../asset/left.svg";
import ModalSection from "./modalSection";
import ComponentVisibilty from "../../component/component-visibility";

const Products = ({
  showModal,
  setShowModal,
  loading,
  currentItems,
  pageCount,
  refReactPaginate,
  handlePageClick,
  isEdit,
  setIsEdit,
  category,
  reGetProducts,
  productVisibility,
  clicked,
  setClicked,
  deleteProduct,
  msg,
  searchInput,
  setSearchInput,
  showDeleteModal,
  setShowDeleteModal,
  onChangeHandler,
}) => {
  console.log(currentItems);
  // mapping all the products here as |mappedProducts|
  const mappedProducts = currentItems
    .filter((v) => v.title.toLowerCase().includes(searchInput.toLowerCase()))
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <div className={`sm:pr-0 pr-20 ${Classes.myOrderItemCover}`}>
          <img
            src={v.product_gallery[0]?.image}
            className={Classes.imgSrc}
            alt="product"
          />
          <strong className={Classes.strongSection}> Name: </strong>
          <span style={{ margin: " 0px 10px", textTransform: "capitalize" }}>
            {v.title}
          </span>
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={`${Classes.strongSection}`}>Category: </strong>
          {v?.category_details?.name}
        </div>

        <div className={`text-center ${Classes.myOrderItemCover}`}>
          <strong className={Classes.strongSection}>Tag: </strong>
          {v?.tag}
        </div>
        <div className={Classes.myOrderItemCover}>
          ₦{v.discount_price.toLocaleString()}
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Date Added: </strong>
          {v.created.split("T")[0]}
        </div>
        <div className={Classes.myOrderItemCover}>
          <button
            className={Classes.visibilityCover}
            onClick={() => productVisibility(v?.pk, !v.is_active)}
          >
            <ComponentVisibilty appear={!!v.inventory}>
              {v.is_active ? "visible" : "hidden"}
            </ComponentVisibilty>

            <ComponentVisibilty appear={!v.inventory}>
              Out of stock
            </ComponentVisibilty>
          </button>
        </div>
        <div className={Classes.myOrderItemCover}>
          <p className={Classes.actionBTNCover}>
            <Trash
              onClick={() => {
                setShowDeleteModal(true);
                setClicked(v);
              }}
              fill="#7f7f7f"
              width="16px"
            />
            <Edit
              onClick={() => {
                setIsEdit(true);
                setClicked(v);
                setShowModal(true);
              }}
            />
            <Link to={`/product/${v.pk}`}>
              <Eye />
            </Link>
          </p>
        </div>
      </div>
    ));

  const mappedLoading = Array(7)
    .fill("a")
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  return (
    <>
      <div className={Classes.flexSection}>
        <Helmet>
          <title>Products - Passward Vendor </title>
          <meta name="og:title" content="Products - Passward Vendor" />
          <meta name="og:description" content="Products - Passward Vendor" />
          <meta
            name="og:name"
            content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
          />
          <meta
            name="og:name"
            content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
          />
        </Helmet>
        <div className="h-screen w-full p-4">
          <Suspense fallback={<LazyLoading />}>
            {/* nav section here is just the top header side */}
            <EachPageHeader first="Home" second="Products" />
            <div className={Classes.subNavCover}>
              <form className={Classes.firstForm}>
                <input
                  placeholder="Search  Product"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className={Classes.searchFormSection}
                />
                <button className={Classes.buttonFormSection}>
                  <AiOutlineSearch fill="#fff" size="1.3em" />
                </button>
              </form>

              <div className={Classes.PageHeaderCover}>
                <h3 className={Classes.eachPageHeader}>Products</h3>
                <div
                  className={Classes.sideEachPageHeader}
                  style={{ width: "max-content" }}
                >
                  <form className={Classes.secondForm}>
                    <input
                      placeholder="Search  Product"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      className={Classes.searchFormSection}
                    />
                    <button className={Classes.buttonFormSection}>
                      <AiOutlineSearch fill="#fff" size="1.3em" />
                    </button>
                  </form>
                  <Button
                    onClick={() => {
                      setShowModal(true);
                      setIsEdit(false);
                    }}
                    text="Add New Product"
                    style={{ height: "max-content", marginTop: "0px" }}
                  />
                </div>
              </div>
            </div>

            {/* here is the listing of all the products with the headers */}
            <div className={Classes.cardCover}>
              {msg.length > 0 && (
                <div
                  className={Classes.msgAlert}
                  style={{
                    textAlign: "center",
                    marginBottom: "40px !important",
                  }}
                >
                  <p
                    style={{ margin: "0px 0px" }}
                    className={Classes.msgItself}
                  >
                    {msg}
                  </p>
                </div>
              )}

              <div className={Classes.mappedStoresCover}>
                <div className={Classes.cardListingCover}>
                  {mappedProducts.length > 0 && (
                    <div className={Classes.cardHeader}>
                      <strong style={{ marginRight: "auto" }}>
                        Product Name
                      </strong>
                      <strong style={{ marginRight: "auto" }}>Category</strong>
                      <strong style={{ marginRight: "auto" }}>Tag</strong>
                      <strong style={{ marginRight: "auto" }}>Price</strong>
                      <strong>Date added</strong>
                      <strong>Status</strong>
                      <strong>Action</strong>
                    </div>
                  )}

                  {loading ? (
                    mappedLoading
                  ) : mappedProducts.length > 0 ? (
                    mappedProducts
                  ) : (
                    <div className={Classes.noProducts}>
                      <p>You do not have any products currently</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!mappedProducts.length < 1 && (
              <div
                style={{ display: "flex", marginBottom: "30px", width: "100%" }}
              >
                <div className={Classes.showingCover}>
                  <select
                    onChange={onChangeHandler}
                    className={Classes.showingSelectCover}
                  >
                    <option>Showing 1</option>
                    <option defaultValue selected>
                      Showing 10
                    </option>
                    <option>Showing 25</option>
                    <option>Showing 50</option>
                    <option>Showing 100</option>
                  </select>
                </div>

                <ReactPaginate
                  ref={refReactPaginate}
                  breakLabel="..."
                  nextLabel={<Left style={{ transform: "rotate(180deg)" }} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={<Left />}
                  className={Classes.paginationClass}
                  renderOnZeroPageCount={null}
                  disabledClassName={Classes.disabledClassName}
                />
              </div>
            )}
          </Suspense>
        </div>
        <ModalSection
          showModal={showModal}
          setShowModal={setShowModal}
          isEdit={isEdit}
          clicked={clicked}
          clearClicked={() => setClicked({})}
          category={category}
          reGetProducts={reGetProducts}
          setClicked={setClicked}
          // setCategory={setCategory}
        />
      </div>

      <Modal show={showDeleteModal} close={() => setShowDeleteModal(false)}>
        <h3 className={Classes.confirmRemoval}>Remove Product</h3>
        <p className={Classes.removed}>
          Are you sure you want to remove{" "}
          <span style={{ color: "#ff0066" }}>{clicked.title}</span>
          <br /> from your product list?
        </p>

        <div style={{ display: "flex" }}>
          <Button
            text="Yes, Remove"
            style={{ width: "160px" }}
            onClick={deleteProduct}
          />
          <div style={{ width: "20px" }}></div>
          <Button
            text="Don't Remove"
            onClick={() => setShowDeleteModal(false)}
            style={{
              backgroundColor: "inherit",
              border: "2px solid #ff0066",
              color: "#ff0066",
              width: "160px",
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default Products;
