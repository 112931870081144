import axios from "../../config/axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useContext } from "react";
import { MainContext } from "../../App";
import { useNavigate } from "react-router-dom";
import useResendVerificationTokenMutation from "./resend-verification-token";

const useLoginMutation = () => {
  const { setToken, setUser } = useContext(MainContext);
  const navigate = useNavigate();
  const { mutate } = useResendVerificationTokenMutation();

  const login = async (loginDetails) => {
    try {
      const request = await axios.post(`vendor/login`, loginDetails);
      const { data } = request;
      toast("login successfully");
      setToken(data.data.access_token);
      setUser(data.data.vendor);
      navigate("/dashboard");
      return data;
    } catch (error) {
      if (error.response.data.detail) {
        toast(error.response.data.detail ?? "Something went wrong");
      }
      if (error.response.data.message) {
        if (error.response.data?.message.includes("verify")) {
          mutate({ email: loginDetails.email });
          toast(error.response.data.message ?? "Something went wrong");
        } else {
          toast(error.response.data.message ?? "Something went wrong");
        }
      }
    }
  };

  const query = useMutation({
    mutationFn: login,
  });

  return query;
};

export default useLoginMutation;
