import axios from "../../config/axios";
import { useQuery } from "@tanstack/react-query";
import useGetUserIpAdressQuery from "../ip-address/get-user-ip";

const useSubscriptionInfoQuery = () => {
  const { data: userIp } = useGetUserIpAdressQuery();

  // console.log("Subscription IP: ", userIp);
  const subscriptionInfo = async () => {
    try {
      const request = await axios.get(`vendor/fetch-info/${userIp.ip}`);
      const { data } = request;
      console.log(data);
      return data;
    } catch (error) {
      // toast(error.response.data.detail ?? "Something went wrong");
    }
  };

  const query = useQuery({
    enabled: !!userIp,
    queryKey: ["subcription-info"],
    queryFn: subscriptionInfo,
  });

  return { query, userIp };
};

export default useSubscriptionInfoQuery;
