import axios from "../../config/axios";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

const useResendVerificationTokenMutation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const resendVerificationToken = async (details) => {
    try {
      const request = await axios.post(`vendor/account/verify/resend`, details);
      const { data } = request;

      if (location.pathname === "/login") {
        navigate(`/verification?email=${details.email}`);
      }
      return data;
    } catch (error) {
      // toast(error.response.data.detail ?? "Something went wrong");
      console.log(error);
    }
  };

  const query = useMutation({
    mutationFn: resendVerificationToken,
  });

  return query;
};

export default useResendVerificationTokenMutation;
