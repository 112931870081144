import React, { useState, useEffect, useRef } from "react";
import Customer from "./customer";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";

const EventCustomer = () => {
  const [itemsPerPage, setItemsPerPage] = useState("10");
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [searchInput, setSearchInput] = useState("");
  const refReactPaginate = useRef();

  const [customers, setCustomers] = useState([]);
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;

  const getAllCustomers = async () => {
    setLoading(true);
    const fetched = await fetch(
      `${process.env.REACT_APP_BASEURL}/vendor/customers`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
      }
    );
    const json = await fetched.json();

    setLoading(false);
    setCustomers(json.data?.map((item) => ({ ...item, selected: false })));
    // setCustomers(customerMsgs?.map(item=>({...item,selected:false})));
  };
  useEffect(() => {
    getAllCustomers();
  }, []);

  useEffect(() => {
    if (customers.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = customers.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(customers.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, customers]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % customers.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const onChangeHandler = (e) => {
    setItemsPerPage(e.target.value.split(" ")[1]);
  };

  return (
    <Customer
      loading={loading}
      showModal={showModal}
      pageNum={pageNum}
      setShowModal={setShowModal}
      editorState={editorState}
      handlePageClick={handlePageClick}
      pageCount={pageCount}
      refReactPaginate={refReactPaginate}
      setEditorState={setEditorState}
      currentItems={currentItems}
      onChangeHandler={onChangeHandler}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      allCustomers={customers}
      setAllCustomers={setCustomers}
    />
  );
};

export default EventCustomer;
