import React from "react";
import Classes from "./eachPageHeader.module.css";
import { ReactComponent as Right } from "../../../asset/right.svg";
import joinClasses from "../../../utils/joinClasses";

export const EachPageHeader = ({ first, second, third }) => {
  return (
    <div
      className={joinClasses(
        "flex items-center font-bold !text-lg text-black gap-3"
      )}
    >
      <small>{first}</small>
      {second && (
        <>
          <Right fill="#7F7F7F" width="18px" />
          <small
            className={joinClasses(
              !third && "text-[#ff0066] ",
              "flex items-center"
            )}
          >
            {second}
          </small>
        </>
      )}

      {third && (
        <>
          <Right fill="#7F7F7F" width="18px" />
          <small
            className={joinClasses(
              third && "text-[#ff0066] ",
              "flex items-center"
            )}
          >
            {third}
          </small>
        </>
      )}
    </div>
  );
};

export const PageHeader = ({ name }) => (
  <h3 className={Classes.eachPageHeader}>{name}</h3>
);
