const GlobalReducer = (state, action) => {
    switch (action.type) {
        case 'CLOSE_NAV':
            return {
                ...state,
                sidebar: action.payload
            }
        case 'OPEN_NAV':
            return {
                ...state,
                sidebar: action.payload
            }
        case 'STORE_STATISTICS':
            return {
                ...state,
                statistics: action.payload
            }
        case 'STORE_USER_DETAILS':
            return {
                ...state,
                user: action.payload
            }
        default:
            return state
    }
}

export default GlobalReducer