import React, { useContext, useState } from "react";
import axios from "axios";
import Reset from "./reset";
import { MainContext } from "../../App";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const EventReset = () => {
  let location = useParams();
  const CTX = useContext(MainContext);
  const [msg, setMsg] = useState("");
  const [selectedStates, setSelectedStates] = useState("Abia");
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  console.log(location);
  const onSubmitHandler = async (e) => {
    e.preventDefault();

    try {
      const url = await axios.post(
        `${process.env.REACT_APP_BASEURL}/vendor/password/reset/${location.token}`,
        {
          new_password: inputs.new_password,
          confirm_password: inputs.confirm_password,
          token: location?.token,
        }
      );
      setLoading(false);
      toast(url.data.data.password);
      navigate("/login");
    } catch (error) {
      console.log("error", error.response);
      setLoading(false);

      if (error.response) {
        toast(
          error?.response?.data?.data?.token ?? error?.response?.data?.message
        );
        return;
      } else {
        toast("Check your internet");
        return;
      }
    }
  };

  return (
    <Reset
      inputs={inputs}
      setInputs={setInputs}
      setSelectedStates={setSelectedStates}
      selectedStates={selectedStates}
      msg={msg}
      setMsg={setMsg}
      onSubmitHandler={onSubmitHandler}
      loading={loading}
      CTX={CTX}
      setLoading={setLoading}
    />
  );
};

export default EventReset;
