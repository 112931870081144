import React, { useRef } from "react";
import Classes from "./input.module.css";
import { ReactComponent as Star } from "../../asset/_.svg";
import { ReactComponent as Eye } from "../../asset/eye.svg";
import { AiOutlineClose } from "react-icons/ai";

const Input = ({
  selected,
  readOnlyMsg = "",
  showPassword,
  label,
  inputType,
  onChange,
  value,
  type,
  options = [],
  style,
  labelStyle,
  onBlur,
  placeholder = "",
  txtPlaceholder = "Short description of store",
  required,
  readOnly,
  accept,
  disabled,
  rows = "3",
  marb = false,
  className,
  multiple,
  removeImage,
  maxLength,
  defaultValue,
}) => {
  const hiddenFileInput = useRef(null);
  return (
    <div
      className={`${Classes.mainCover}`}
      style={{ marginBottom: marb ? "0px" : "1rem" }}
    >
      <label
        style={labelStyle}
        className={`flex ${Classes.label}`}
        htmlFor={label}
      >
        {label}
        {required && (
          <span style={{ marginLeft: "4px" }}>
            <Star />
          </span>
        )}
      </label>
      {label.includes("Password") && (
        <Eye onClick={showPassword} className={Classes.EyeSVG} />
      )}
      {type === "input" && (
        <input
          autoComplete="nope"
          readOnly={readOnly}
          accept={accept}
          onBlur={onBlur}
          required={required}
          placeholder={placeholder}
          className={Classes.inputCover}
          style={{
            backgroundColor: readOnly ? "#f9f9f9" : "inherit",
            color: readOnly ? "#969696" : "#6c757d",
            ...style,
          }}
          value={value}
          onChange={onChange}
          type={inputType ? inputType : type}
          maxLength={maxLength}
          disabled={disabled}
        />
      )}
      {type === "number" && (
        <input
          autoComplete="nope"
          readOnly={readOnly}
          accept={accept}
          onBlur={onBlur}
          required={required}
          placeholder={placeholder}
          className={Classes.inputCover}
          style={{
            backgroundColor: readOnly ? "#f9f9f9" : "inherit",
            color: readOnly ? "#969696" : "#6c757d",
            ...style,
          }}
          value={value}
          onChange={onChange}
          type={type}
          min="1"
        />
      )}
      {type === "time" && (
        <input
          autoComplete="nope"
          onBlur={onBlur}
          required={required}
          placeholder={placeholder}
          className={Classes.inputCover}
          style={{
            backgroundColor: readOnly ? "#f9f9f9" : "inherit",
            color: readOnly ? "#969696" : "#6c757d",
            ...style,
          }}
          value={value}
          onChange={onChange}
          type={type}
        />
      )}
      {type === "url" && (
        <input
          autoComplete="nope"
          readOnly={readOnly}
          accept={accept}
          onBlur={onBlur}
          required={required}
          placeholder={placeholder}
          className={Classes.inputCover}
          style={{
            backgroundColor: readOnly ? "#f9f9f9" : "inherit",
            color: readOnly ? "#969696" : "#6c757d",
            ...style,
          }}
          value={value}
          onChange={onChange}
          type={type}
        />
      )}
      {type === "select" && (
        <select
          className={Classes.inputCover}
          style={style}
          required={required}
          onChange={onChange}
          defaultValue={defaultValue ?? ""}
        >
          {options.map((v, i) => (
            <option
              selected={selected === v && selected}
              key={(v, i)}
              value={v?.toLowerCase()?.includes("choose") ? "" : v}
            >
              {v}
            </option>
          ))}
        </select>
      )}
      {type === "textarea" && (
        <textarea
          required={required}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={txtPlaceholder}
          style={{ height: "auto", resize: "none" }}
          onChange={onChange}
          className={Classes.inputCover}
          rows={rows}
          maxLength={maxLength}
        ></textarea>
      )}
      {type === "file" && (
        <div className="bg-[#000033] mt-1 justify-between rounded-lg flex  p-2">
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={(e) =>
              onChange(multiple ? e.target.files : e.target.files[0])
            }
            className={`hidden`}
            accept={accept}
            multiple={multiple ? multiple : false}
          />
          <button
            onClick={() => hiddenFileInput.current.click()}
            className={`${className} whitespace-nowrap overflow-hidden rounded-md`}
            type="button"
            htmlFor=""
          >
            {placeholder}
          </button>
          <button className="text-white" onClick={() => removeImage()}>
            <AiOutlineClose />
          </button>
        </div>
      )}
      {readOnlyMsg.length > 3 && (
        <small className={Classes.readOnlyMsg}>{readOnlyMsg}</small>
      )}
    </div>
  );
};

export default Input;
