import React, { useEffect, useState, useRef } from "react";
import { Months } from "../../data/monthsJson";
import Wallet from "./wallet";

const EventWallet = () => {
  const [itemsPerPage, setItemsPerPage] = useState("10");

  const [showModal, setShowModal] = useState(false);
  const [monthly_income, setMonthly_income] = useState({
    withdraw_history: [],
  });
  const [loading, setLoading] = useState(false);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const refReactPaginate = useRef();
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;

  const selectOnChangeHandler = async (e) => {
    setLoading(true);

    const fetched = await fetch(
      `${process.env.REACT_APP_BASEURL}/vendor/wallet?${
        e === "All Revenue" ? "" : `month=${e}`
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
      }
    );
    const json = await fetched.json();

    setLoading(false);
    setMonthly_income(json?.data);
  };

  useEffect(() => {
    selectOnChangeHandler(Months[new Date().getMonth()].name);
  }, []);

  useEffect(() => {
    if (monthly_income.withdraw_history.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = monthly_income.withdraw_history.slice(
      itemOffset,
      endOffset
    );
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(
      Math.ceil(monthly_income.withdraw_history.length / itemsPerPage)
    );
  }, [itemOffset, itemsPerPage, monthly_income]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset =
      (event.selected * itemsPerPage) %
      monthly_income.withdraw_history.length.length;
    setItemOffset(newOffset);
  };

  const onChangeHandler = (e) => {
    setItemsPerPage(e.target.value.split(" ")[1]);
  };

  return (
    <Wallet
      loading={loading}
      currentItems={currentItems}
      pageCount={pageCount}
      pageNum={pageNum}
      monthly_income={monthly_income}
      refReactPaginate={refReactPaginate}
      showModal={showModal}
      selectOnChangeHandler={selectOnChangeHandler}
      setShowModal={setShowModal}
      onChangeHandler={onChangeHandler}
      // reloadPage={selectOnChangeHandler(Months[new Date().getMonth()].name)}
      handlePageClick={handlePageClick}
    />
  );
};

export default EventWallet;
