import React, {
  Suspense,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { Helmet } from "react-helmet";
import { AiOutlineLock, AiTwotoneCamera, AiOutlineUser } from "react-icons/ai";
import avatar from "../../asset/avatar.png";
import Card from "../../component/card/card";
import Button from "../../component/button/button";
import Classes from "./withdrawalSettings.module.css";
import RightSide from "./rightSide";
import ChangePass from "./changePass";
import { MainContext } from "../../App";
import { GetUserDetails } from "../../helper/getUserHelper";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import {
  EachPageHeader,
  PageHeader,
} from "../../component/layout/eachPageHeader/eachPageHeader";
import ChangeAgent from "./changeAgent";
import axios from "axios";
import Input from "../../component/input/input";
import toast from "react-hot-toast";
import { GlobalContext } from "../../context/GlobalProvider";
import ComponentVisibilty from "../../component/component-visibility";
import joinClasses from "../../utils/joinClasses";

const WithdrawalSettings = () => {
  const CTX = useContext(MainContext);
  const context = useContext(GlobalContext);
  const [password, setPassword] = useState(false);
  const [agentModal, setAgentModal] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [requestedAgent, setRequestedAgent] = useState({});
  const [message, setMessage] = useState("");
  const [requestAgent, setRequestAgent] = useState(false);
  const [myAgent, setMyAgent] = useState({});
  const { getUser } = GetUserDetails();
  const inputRefHere = useRef();
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;
  const getAgentDetail = (agent) => {
    setRequestedAgent(agent);
  };

  const onBlurImageHandler = async (e) => {
    try {
      var formData = await new FormData();
      formData.append("photo", e.target.files[Object.keys(e.target.files)]);
      await axios({
        url: `${process.env.REACT_APP_BASEURL}/vendor/setting/avatar/update`,
        data: formData,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
      });
      getUser();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/vendor/setting/agents`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${_tk}`,
            },
          }
        );
        const fetchedData = await response.json();
        const { status } = fetchedData;
        if (status !== 200)
          throw new Error("Could not get agents at the moments");
      } catch (e) {
        toast(e.message);
      }
      context.storeUserDetails();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/vendor/agents/assign`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${_tk}`,
            },
          }
        );
        const fetchedData = await response.json();
        const { data, status } = fetchedData;
        if (status !== 200) throw new Error("Opps something went wrong");
        setMyAgent(data);
      } catch (e) {
        setMyAgent({});
      }
    })();
  }, []);

  const handleRequestAgent = () => {
    if (!requestAgent) {
      setAgentModal(true);
    } else {
      setRequestAgent((prev) => !prev);
      setRequestedAgent({});
      setMessage("");
    }
  };

  const handleReason = async () => {
    try {
      setRequestAgent(false);

      const request = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/setting/agent/change`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
          body: JSON.stringify({ agent: requestedAgent.pk, reason: message }),
        }
      );
      const { data, status } = await request.json();

      if (status !== 200 && data) throw new Error(data.agent);
      if (status !== 200 && !data) throw new Error("Opps something went wrong");
      setMessage("");
      setRequestAgent(false);
      setRequestedAgent({});
      toast("Reason submitted");
    } catch (e) {
      toast(e.message);
    }
  };
  return (
    <>
      {/* change password modal */}
      <ChangePass
        show={password}
        close={() => setPassword(false)}
        showAgent={agentModal}
        closeAgent={() => setAgentModal(false)}
        showImage={showImage}
        closeImage={() => setShowImage(false)}
      />
      <ChangeAgent
        getAgentDetail={getAgentDetail}
        showAgent={agentModal}
        closeAgent={() => setAgentModal(false)}
        setRequestAgent={setRequestAgent}
      />

      <Helmet>
        <title>Settings - Passward Vendor </title>
        <meta name="og:title" content="Settings - Passward Vendor" />
        <meta name="og:description" content="Settings - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={Classes.flexSection}>
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          style={{
            position: "absolute",
            top: "-3000px",
            left: "-3000px",
            visibility: "hidden",
          }}
          onChange={onBlurImageHandler}
          ref={inputRefHere}
        />

        <div className="h-screen w-full sm:p-4 py-5">
          <Suspense fallback={<LazyLoading />}>
            {/* nav section here is just the top header side */}
            <div className="pl-3 md:pl-0">
              <EachPageHeader first="Home" second="Settings" />

              <div className="my-2">
                <PageHeader name="Settings" />
              </div>
            </div>

            <div className={Classes.gridTwoFiles}>
              <div className={Classes.firstCardCover}>
                <div className={Classes.pageHeader}>Store Settings</div>
                <Card>
                  <div className={Classes.paddingCard}>
                    {!CTX.user.photo ? (
                      <div
                        onClick={() => inputRefHere.current.click()}
                        className={joinClasses(
                          Classes.SidebarHeaderRoundCover,
                          "cursor-pointer"
                        )}
                      >
                        <AiOutlineUser size="2.3em" fill="#fff" />

                        <div
                          style={{
                            position: "absolute",
                            bottom: "8px",
                            right: "8px",
                            backgroundColor: "rgb(0, 0, 51)",
                            width: "1em",
                            height: "1em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                        >
                          <AiTwotoneCamera size=".7em" fill="#fff" />
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() => inputRefHere.current.click()}
                        style={{
                          width: "max-content",
                          height: "max-content",
                          position: "relative",
                          margin: "auto",
                        }}
                      >
                        <img
                          className={Classes.avatar}
                          src={CTX.user.photo}
                          alt="avatar"
                        />
                        <div
                          style={{
                            position: "absolute",
                            bottom: "8px",
                            right: "8px",
                            backgroundColor: "rgb(0, 0, 51)",
                            width: "1em",
                            height: "1em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                          onClick={() => inputRefHere.current.click()}
                        >
                          <AiTwotoneCamera style={{}} size=".7em" fill="#fff" />
                        </div>
                      </div>
                    )}
                    <strong className={Classes.profileName}>
                      {CTX.user.first_name} {CTX.user.last_name}
                    </strong>

                    <div style={{ width: "max-content", margin: "auto" }}>
                      <Button
                        bgColor="#f1556c"
                        style={{ backgroundColor: "#000033", border: "none" }}
                        text="Change Password"
                        onClick={() => setPassword(true)}
                        svg={<AiOutlineLock style={{ marginRight: "8px" }} />}
                      />
                    </div>

                    <div className={Classes.textLeft}>
                      <p className="text-muted mb-2 font-13">
                        <strong>Full Name:</strong>{" "}
                        <span
                          className={Classes.ml_2}
                          style={{ textTransform: "capitalize" }}
                        >
                          {CTX.user.first_name} {CTX.user.last_name}
                        </span>
                      </p>

                      <p className="text-muted mb-2 font-13">
                        <strong>Phone Number:</strong>
                        <span className={Classes.ml_2}>{CTX.user.phone}</span>
                      </p>

                      <p className="text-muted mb-2 font-13">
                        <strong>Email Address:</strong>{" "}
                        <span className={Classes.ml_2}>{CTX.user.email}</span>
                      </p>

                      {/* <p className="text-muted mb-1 font-13">
                        <strong>State:</strong>{' '}
                        <span className={Classes.ml_2}>{CTX.user.state}</span>
                      </p> */}
                    </div>
                  </div>
                </Card>
                {Object.keys(myAgent).length ? (
                  <Card>
                    <div className={Classes.paddingCard}>
                      <img
                        className={Classes.avatar}
                        src={avatar}
                        alt="avatar"
                      />
                      <strong className={Classes.profileName}>
                        {myAgent.first_name} {myAgent.last_name}
                      </strong>

                      {/* <div style={{width: '170px', margin: 'auto'}}>
                      <Button
                        onClick={() => setShowImage(!showImage)}
                        bgColor="#f1556c"
                        style={{
                          margin: '10px auto auto auto',
                          backgroundColor: '#000033',
                          border: 'none',
                        }}
                        text="Change Profile Image"
                      />
                    </div> */}

                      <div className={Classes.textLeft}>
                        <p className="text-muted mb-2 font-13">
                          <strong>Agent Name:</strong>{" "}
                          <span className={Classes.ml_2}>
                            {myAgent.first_name} {myAgent.last_name}
                          </span>
                        </p>

                        <p className="text-muted mb-2 font-13">
                          <strong>Agent Phone:</strong>
                          <span className={Classes.ml_2}>
                            {" "}
                            {myAgent.phone ? myAgent.phone : "Not updated"}
                          </span>
                        </p>

                        <p className="text-muted mb-2 font-13">
                          <strong>Agent Email:</strong>{" "}
                          <span className={Classes.ml_2}>{myAgent.email}</span>
                        </p>
                      </div>
                      <Button
                        onClick={() => setAgentModal(!agentModal)}
                        bgColor="#f1556c"
                        style={{
                          margin: "auto",
                          marginTop: "10px",
                          backgroundColor: "inherit",
                          color: "#ff0066",
                        }}
                        text="Change Agent"
                      />
                    </div>
                  </Card>
                ) : (
                  <div className="text-center">
                    <button
                      onClick={handleRequestAgent}
                      className="font-bold bg-[#ff0066] p-3 mb-5 text-white rounded-lg text-center transition-all"
                    >
                      {!requestAgent ? "Request agent" : "Cancel Request"}
                    </button>
                  </div>
                )}
                <div
                  className={`${
                    !requestAgent
                      ? "h-0 translate-y-[-10%]"
                      : "h-full translate-y-[0%]"
                  } transition-all  overflow-hidden`}
                >
                  <Input
                    txtPlaceholder=" "
                    label="Add a reason for the request:"
                    type="textarea"
                    inputType="password"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    maxLength={100}
                  />
                  <ComponentVisibilty appear={message.length === 100}>
                    <p className="text-xs text-[#ff0066] my-2">
                      character limit is exceeded
                    </p>
                  </ComponentVisibilty>
                  <div className="text-right text-white">
                    <button
                      disabled={message ? false : true}
                      className={`p-2 ${
                        !message && "opacity-[.6]"
                      } rounded-lg font-bold text-sm bg-[#ff0066]`}
                      onClick={handleReason}
                    >
                      Send Request
                    </button>
                  </div>
                </div>
              </div>

              <RightSide />
            </div>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default WithdrawalSettings;
