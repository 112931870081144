import React, { useContext, useEffect, useState } from "react";
import Classes from "./dashboard.module.css";
import {
  EachPageHeader,
  PageHeader,
} from "../../component/layout/eachPageHeader/eachPageHeader";
import { Helmet } from "react-helmet";
import DashboardCard from "../../component/dashboardCard/dashboardCard";
// import {ReactComponent as Money} from '../../asset/money.svg';
import { ReactComponent as Customers } from "../../asset/u_users-alt.svg";
import { ReactComponent as Box } from "../../asset/u_box.svg";
import { ReactComponent as Bag } from "../../asset/bag.svg";
import RecentOrder from "./recentOrder";
import { AiOutlineWallet } from "react-icons/ai";
import { GetUserStatistics } from "../../helper/getUserStatistics";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import GraphSection from "./graphSection";
import { MainContext } from "../../App";

const Home = () => {
  const { data, getSta } = GetUserStatistics();
  const CTX = useContext(MainContext);
  const [graph, setGraph] = useState([
    {
      month: "Jan",
      revenue: 0,
    },
    {
      month: "Feb",
      revenue: 0,
    },
    {
      month: "Mar",
      revenue: 0,
    },
    {
      month: "Apr",
      revenue: 0,
    },
    {
      month: "May",
      revenue: 0,
    },
    {
      month: "Jun",
      revenue: 0,
    },
    {
      month: "Jul",
      revenue: 0,
    },
    {
      month: "Aug",
      revenue: 0,
    },
    {
      month: "Sep",
      revenue: 0,
    },
    {
      month: "Oct",
      revenue: 0,
    },
    {
      month: "Nov",
      revenue: 0,
    },
    {
      month: "Dec",
      revenue: 0,
    },
  ]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  // setting up chartJS line details
  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          drawBorder: false,
          padding: 20,
          color: "#003",
        },
        grid: {
          borderDash: [10, 10],
          color: "#969696",
        },
      },
      y: {
        ticks: {
          color: "#003",
          padding: 20,
          borderDash: [10, 10],
        },

        grid: {
          borderDash: [10, 10],
          color: "#969696",
        },
        min: 0,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const labels = graph.map((v) => v.month);
  // console.log(graph.map((v) => ({y:v.revenue,x:v.month})))
  const graphData = {
    labels,
    datasets: [
      {
        label: " ",
        data: graph.map((v) => ({ y: v.revenue, x: v.month })),
        // data: [23, 34, 54, 64, 64, 23, 100, 200, 900, 232, 434, 534],
        pointRadius: 0,
        width: "1px",
        color: "#000033",
        borderColor: "#969696",
      },
    ],
  };
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;
  // function to get revenue graph chart data from BackEnd with year as the params
  const getUserGraph = async (year) => {
    try {
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/vendor/overview/revenue/chart?query_year=${year}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${_tk}`,
          },
        }
      );
      const json = await fetched.json();
      setGraph(json.data.data);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  // useEffect that get user statistics on default when page loads  //
  // useEffect(() => {
  // console.log("data.balance => ", data.balance)
  // if (data.balance) {
  // }
  // }, [data]);

  useEffect(() => {
    getSta();
  }, []);

  // useEffect that gets user year data on default when page loads
  useEffect(() => {
    getUserGraph(new Date().getFullYear());
  }, []);

  return (
    <div className={`${Classes.flexSection}`}>
      <Helmet>
        <title>Overview - Passward Vendor </title>
        <meta name="og:title" content="Overview - Passward Vendor" />
        <meta name="og:description" content="Settings - Passward Vendor" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      {/* <div
        onClick={()=>{CTX.removeSideBar();closeNav()}}
        style={{
          padding: '0px 20px',
          height: "100vh",
          boxSizing: 'border-box',
          width: '100%',
          filter: 'blur(var(--body-blur,0px))',
        }}
      > */}
      <div className="h-screen w-full p-4">
        <EachPageHeader first="Home" second="Overview" />
        <PageHeader name="Overview" />
        <div className={Classes.gridDashboardCard}>
          <DashboardCard
            label="Wallet Balance"
            num={
              "₦" + " " + `${data.balance === undefined ? "0" : data.balance}`
            }
            svg={<AiOutlineWallet />}
          />
          <DashboardCard
            label="Orders"
            num={data.order === undefined ? "0" : data.order}
            svg={<Bag />}
          />
          <DashboardCard
            label="Products"
            num={data.product === undefined ? "0" : data.product}
            svg={<Box />}
          />
          <DashboardCard
            label="Customers"
            num={data.customer === undefined ? "0" : data.customer}
            svg={<Customers fill="#003" />}
          />
        </div>

        {/* </div> */}
        <strong className="mt-5 block text-right">
          Store: {CTX?.user?.store_detail.name}
        </strong>

        <div className={Classes.firstGraph}>
          <div className={Classes.headerSection}>
            <strong
              style={{ color: "#003" }}
              className={Classes.strongHeaderHERE}
            >
              Revenue
            </strong>

            <select
              className={Classes.selectSectionHere}
              onChange={(e) => getUserGraph(e.target.value)}
            >
              <option>{new Date().getFullYear()}</option>
              <option>{new Date().getFullYear() - 1}</option>
              <option>{new Date().getFullYear() - 2}</option>
              <option>{new Date().getFullYear() - 3}</option>
            </select>
          </div>

          {/* the graph is here */}
          <div style={{ height: "23px" }}></div>

          <div className={Classes.padGraphSection}>
            <Line options={options} data={graphData} />
          </div>
        </div>
        <GraphSection />
        <RecentOrder />
      </div>
    </div>
  );
};

export default Home;
