import React from "react";
import styles from "./styles.module.css";
import joinClasses from "../../utils/joinClasses";
const Spinner = ({ className, size, color }) => {
  const borderConfig = {
    sizes: {
      xs: " !h-[20px] !w-[20px] !border-2",
      sm: " !h-[40px] !w-[40px] !border-4",
    },
    colors: {
      primary: "border-[#ff0066]",
      secondary: "border-[white]",
    },
  };

  return (
    <div className={joinClasses(className, "w-fit")}>
      <span
        className={joinClasses(
          styles.loader,
          borderConfig.sizes[size] ?? borderConfig.sizes["xs"],
          borderConfig.colors[color] ?? borderConfig.colors["primary"]
        )}
      ></span>
    </div>
  );
};

export default Spinner;
