import React, { useEffect, useState } from "react";
import Classes from "./singleProduct.module.css";
import { Helmet } from "react-helmet";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";
import SingleProductPage from "./singleProduct/eachSingleProduct";
import { useParams } from "react-router-dom";
import joinClasses from "../../utils/joinClasses";

const SingleProduct = () => {
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({ product_gallery: [] });
  const _tk =
    typeof window !== undefined
      ? JSON.parse(sessionStorage.getItem("v_tk"))
      : null;

  const getSingleProduct = async () => {
    setLoading(true);
    const fetched = await fetch(
      `${process.env.REACT_APP_BASEURL}/vendor/product/get/${param.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${_tk}`,
        },
      }
    );
    const json = await fetched.json();

    setLoading(false);
    setProduct(json.data);
  };

  useEffect(() => {
    getSingleProduct();
  }, []);

  return (
    <>
      <div className={Classes.flexSection}>
        <Helmet>
          <title>Single Products - Passward Vendor </title>
          <meta name="og:title" content="Single Products - Passward Vendor" />
          <meta
            name="og:description"
            content="Single Products - Passward Vendor"
          />
          <meta
            name="og:name"
            content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
          />
          <meta
            name="og:name"
            content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
          />
        </Helmet>
        <div className="h-screen w-full p-4">
          <EachPageHeader first="Home" second="Product" />
          <h3 className={joinClasses(Classes.eachPageHeader, "mb-5")}>
            Product
          </h3>

          <SingleProductPage data={product} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default SingleProduct;
