import React, { useContext, useState, useEffect } from "react";
import {
  AiOutlineUser,
  AiOutlineLogout,
  AiOutlineShopping,
  AiOutlineSetting,
  AiOutlineWallet,
} from "react-icons/ai";
import Classes from "./sidebar.module.css";
import { ReactComponent as Left } from "../../../asset/left.svg";
import { ReactComponent as Customer } from "../../../asset/customer.svg";
import { ReactComponent as Apps } from "../../../asset/apps.svg";
import { ReactComponent as Box } from "../../../asset/box.svg";
import { ReactComponent as Transaction } from "../../../asset/transaction.svg";
import { ReactComponent as USD } from "../../../asset/usd_circle.svg";
import { NavLink, Link } from "react-router-dom";
import { Modal } from "../../modal/modal";
import { MainContext } from "../../../App";
import Button from "../../button/button";

import { GlobalContext } from "../../../context/GlobalProvider";

const Sidebar = () => {
  const CTX = useContext(MainContext);
  const { sidebar, closeNav, storeStatistics, statistics } =
    useContext(GlobalContext);
  const [showModal, setShowModal] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const onClickSettingHandler = (e) => {
    e.preventDefault();
    setShowSettings(!showSettings);
    setShowTransaction(false);
  };

  const onClickTransactionHandler = (e) => {
    e.preventDefault();
    setShowSettings(false);
    setShowTransaction(!showTransaction);
  };

  useEffect(() => {
    storeStatistics();
  }, []);
  return (
    <nav>
      <div
        className={`fixed transition-all z-[115]
    lg:top-[100px]
         top-0 left-0 lg:translate-x-0 ${
           sidebar ? "translate-x-0" : "translate-x-[-333px]"
         }  h-full z-50`}
      >
        <aside className={`h-full ${Classes.mainSideBarSection}`}>
          <div className={Classes.SidebarMainCover}>
            <div className={Classes.SidebarHeaderCover}>
              <div
                className={`${!CTX.user.photo && "bg-[#ff0066]"} ${
                  Classes.SidebarHeaderRoundCover
                }`}
              >
                {!CTX.user.photo ? (
                  <AiOutlineUser size="2.3em" fill="#fff" />
                ) : (
                  <img
                    src={CTX.user.photo}
                    alt="Profile"
                    className={Classes.ProfileIMG}
                  />
                )}
              </div>
              <h5 className={Classes.SidebarUsername}>
                {CTX.user.first_name} {CTX.user.last_name}
              </h5>
            </div>
            <div
              className={`overflow-auto px-5  pb-[100px] ${Classes.SidebarLinkCover}`}
            >
              <EachLink
                svg={<Apps width="1.3em" />}
                name="Overview"
                to="/dashboard"
                onClick={() => closeNav()}
              />
              {/* <EachLink svg={<AiOutlineUser size="1.3em" />} to="/profile" name="Profile" /> */}
              <EachLink
                svg={<Customer size="1.3em" />}
                name="Customer"
                right={statistics?.customer}
                to="/customers"
                onClick={() => closeNav()}
              />
              <EachLink
                svg={<Box width="1.3em" />}
                name="Products"
                right={statistics?.product}
                to="/products"
                onClick={() => closeNav()}
              />
              <EachLink
                svg={<AiOutlineShopping size="1.3em" />}
                name="Orders"
                right={statistics?.order}
                to="/my-orders"
                onClick={() => closeNav()}
              />
              <EachLink
                svg={<Transaction size="1.3em" />}
                name="Transaction"
                right={
                  <Left
                    width="1.2em"
                    style={{ fill: "#7F7F7F", transform: "rotate(-90deg)" }}
                  />
                }
                onClick={onClickTransactionHandler}
              />
              <div
                style={{ height: showTransaction ? "max-content" : "0px" }}
                className={Classes.semiLinkCover}
              >
                <EachLink
                  svg={<AiOutlineWallet size="1.3em" />}
                  style={{ margin: "0px ", padding: "0px" }}
                  name="Wallet"
                  to="/wallet"
                  onClick={() => closeNav()}
                />
                <EachLink
                  svg={<USD size="1.3em" />}
                  style={{
                    margin: "10px 0px 0px 0px ",
                    padding: "10px 0px 0px 0px ",
                  }}
                  name="Subscription"
                  to="/subscription"
                  onClick={() => closeNav()}
                />
              </div>

              <EachLink
                svg={<AiOutlineSetting size="1.3em" />}
                name="Settings"
                style={{
                  marginTop: !showTransaction && "0px",
                  paddingTop: !showTransaction && "5px",
                }}
                right={
                  <Left
                    width="1.2em"
                    style={{ fill: "#7F7F7F", transform: "rotate(-90deg)" }}
                  />
                }
                onClick={onClickSettingHandler}
              />
              <div
                style={{ height: showSettings ? "max-content" : "0px" }}
                className={Classes.semiLinkCover}
              >
                <Link
                  to="/settings?type=account"
                  style={{ textDecoration: "none" }}
                  onClick={() => closeNav()}
                >
                  <div
                    style={{ margin: "0px ", padding: "0px" }}
                    className={Classes.EachLinkCover}
                  >
                    <AiOutlineWallet size="1.3em" />
                    <h5>Account</h5>
                    <strong></strong>
                  </div>
                </Link>

                <Link
                  to="/settings?type=withdrawal"
                  style={{ textDecoration: "none" }}
                  onClick={() => closeNav()}
                >
                  <div
                    style={{
                      margin: "10px 0px 0px 0px ",
                      padding: "10px 0px 0px 0px ",
                    }}
                    className={Classes.EachLinkCover}
                  >
                    <USD size="1.3em" />
                    <h5>Withdrawal</h5>
                    <strong></strong>
                  </div>
                </Link>
              </div>

              <EachLink
                svg={<AiOutlineLogout size="1.3em" />}
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(!showModal);
                }}
                name="Log Out"
              />
            </div>
          </div>
        </aside>
      </div>
      <Modal show={showModal} close={() => setShowModal(false)}>
        <h3 className={Classes.confirmRemoval}>Log out of Passward</h3>
        <p className={Classes.removed}>
          This will log you out of your account, all your data will be saved.
          Continue to Log Out?
        </p>

        <div style={{ display: "flex" }}>
          <Button
            text="Yes, Log Out"
            style={{ width: "160px" }}
            onClick={() => {
              CTX.logout();
              setShowModal(false);
            }}
          />
          <div style={{ width: "20px" }}></div>
          <Button
            text="Don't Log Out"
            onClick={() => setShowModal(false)}
            style={{
              backgroundColor: "inherit",
              border: "2px solid #ff0066",
              color: "#ff0066",
              width: "160px",
            }}
          />
        </div>
      </Modal>
    </nav>
  );
};

export default Sidebar;

const EachLink = ({ onClick, name, svg, right, to = "/", style }) => (
  <>
    {to ? (
      <NavLink to={to} onClick={onClick}>
        <button style={style} className={`w-full ${Classes.EachLinkCover}`}>
          {svg}
          <h5>{name}</h5>
          <strong>{right}</strong>
        </button>
      </NavLink>
    ) : (
      <div style={style} className={Classes.EachLinkCover}>
        {svg}
        <h5>{name}</h5>
        <strong>{right}</strong>
      </div>
    )}
  </>
);
